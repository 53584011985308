import React from 'react';
import { ChevronRight, Settings } from "lucide-react";
import QuickLaunchBar from '../components/QuickLaunchBar';
import DashboardTile from '../components/DashboardTile';
import OnboardingItem from '../components/OnboardingItem';
import InfoTile from '../components/InfoTile';
import { LibrariesTile } from '../components/LibrariesTile';
import { DocumentQueueTile } from '../components/DocumentQueueTile';
import { PatientsTile } from '../components/PatientsTile';

function Dashboard() {
  return (
    <main className="flex-1 overflow-y-auto p-8">
      <h1 className="text-3xl font-bold text-gray-900 dark:text-white mb-8">Kalamazoo ADHD Clinic</h1>
      <QuickLaunchBar />
      <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
        <DashboardTile 
          title="Onboarding" 
          icon={<ChevronRight className="h-6 w-6" />}
        >
          <div className="space-y-2">
            <OnboardingItem isDone={true} text="Add Service" />
            <OnboardingItem isDone={false} text="Patient Record" />
            <OnboardingItem isDone={false} text="Configure Calendar" />
          </div>
        </DashboardTile>
        <DashboardTile title="Appointments">
          <div className="text-3xl font-bold text-gray-300 dark:text-gray-600">--</div>
          <p className="text-xs text-gray-500 dark:text-gray-400">No data available</p>
        </DashboardTile>
        <PatientsTile />
        <LibrariesTile />
        <DocumentQueueTile />
        <DashboardTile title="Billing">
          <div className="text-3xl font-bold text-gray-300 dark:text-gray-600">--</div>
          <p className="text-xs text-gray-500 dark:text-gray-400">No data available</p>
        </DashboardTile>
        <DashboardTile title="Notifications">
          <div className="text-3xl font-bold text-gray-300 dark:text-gray-600">--</div>
          <p className="text-xs text-gray-500 dark:text-gray-400">No data available</p>
        </DashboardTile>
        <DashboardTile title="Analytics">
          <div className="text-3xl font-bold text-gray-300 dark:text-gray-600">--</div>
          <p className="text-xs text-gray-500 dark:text-gray-400">No data available</p>
        </DashboardTile>
        <InfoTile
          title="Clinic Information"
          icon={<Settings className="h-6 w-6" />}
          settingsIcon={true}
        >
          <div className="space-y-2">
            <p className="text-sm">Patient Portal: https://mypsychaccess.com/m/myclinic</p>
            <p className="text-sm">Outbound email: yourclinic@mypsychaccess.com</p>
            <p className="text-sm">Document email: inbound-1871@inbound.mypsychaccess.com</p>
          </div>
        </InfoTile>
      </div>
    </main>
  );
}

export default Dashboard;