import React, { useState } from 'react';
import { X, Baby, ChevronRight, UserPlus } from 'lucide-react';
import { Button } from "../components/ui/button";
import { Checkbox } from "../components/ui/checkbox";
import { Input } from "../components/ui/input";
import { Label } from "../components/ui/label";
import { RadioGroup, RadioGroupItem } from "../components/ui/radio-group";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../components/ui/select";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../components/ui/dialog";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "../components/ui/collapsible";

export default function PatientIntakeModal() {
  const [isMinorInfoOpen, setIsMinorInfoOpen] = useState(false);
  const [source, setSource] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [patientName, setPatientName] = useState({ given: '', surname: '' });
  const [serviceType, setServiceType] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitted(true);
  };

  const modalContent = isSubmitted ? (
    <div className="text-center space-y-4">
      <h2 className="text-2xl font-bold">Success!</h2>
      <p>{`${patientName.given} ${patientName.surname} has been added.`}</p>
      <Button>Go To Record</Button>
    </div>
  ) : (
    <>
      <DialogHeader>
        <DialogTitle className="text-2xl font-bold">
          Patient Intake Form
        </DialogTitle>
        <Button
          variant="ghost"
          size="icon"
          className="absolute right-4 top-4"
          onClick={() => document.querySelector('dialog')?.close()}
        >
          <X className="h-4 w-4" />
          <span className="sr-only">Close</span>
        </Button>
      </DialogHeader>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="grid grid-cols-2 gap-4">
          <div className="space-y-2">
            <Label htmlFor="givenName">Given Name</Label>
            <Input id="givenName" onChange={(e) => setPatientName(prev => ({ ...prev, given: e.target.value }))} />
          </div>
          <div className="space-y-2">
            <Label htmlFor="surname">Surname</Label>
            <Input id="surname" onChange={(e) => setPatientName(prev => ({ ...prev, surname: e.target.value }))} />
          </div>
          <div className="space-y-2">
            <Label htmlFor="email">Email</Label>
            <Input id="email" type="email" />
          </div>
          <div className="space-y-2">
            <Label htmlFor="goesBy">Goes By</Label>
            <Input id="goesBy" />
          </div>
          <div className="space-y-2">
            <Label htmlFor="phone">Phone</Label>
            <Input id="phone" type="tel" />
          </div>
          <div className="space-y-2">
            <Label htmlFor="serviceType">Service Type</Label>
            <Select onValueChange={setServiceType}>
              <SelectTrigger id="serviceType" className="bg-white">
                <SelectValue placeholder="Select service type" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="assessment">Assessment</SelectItem>
                <SelectItem value="therapy">Therapy</SelectItem>
                <SelectItem value="supervision">Supervision</SelectItem>
                <SelectItem value="consult">Consult</SelectItem>
              </SelectContent>
            </Select>
          </div>
          {serviceType === 'assessment' && (
            <div className="space-y-2">
              <Label htmlFor="assessment">Assessment Type</Label>
              <Select>
                <SelectTrigger id="assessment" className="bg-white">
                  <SelectValue placeholder="Select assessment" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="adhd">ADHD</SelectItem>
                  <SelectItem value="anxiety">Anxiety</SelectItem>
                  <SelectItem value="depression">Depression</SelectItem>
                  <SelectItem value="pre-consult">Pre-Consult (Free)</SelectItem>
                </SelectContent>
              </Select>
            </div>
          )}
          <div className="space-y-2">
            <Label htmlFor="pronouns">Pronouns</Label>
            <Select>
              <SelectTrigger id="pronouns" className="bg-white">
                <SelectValue placeholder="Select pronouns" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="he/him">He/Him</SelectItem>
                <SelectItem value="she/her">She/Her</SelectItem>
                <SelectItem value="they/them">They/Them</SelectItem>
                <SelectItem value="other">Other</SelectItem>
              </SelectContent>
            </Select>
          </div>
          <div className="space-y-2">
            <Label htmlFor="clinician">Clinician</Label>
            <Select>
              <SelectTrigger id="clinician" className="bg-white">
                <SelectValue placeholder="First Available" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="first">First Available</SelectItem>
                <SelectItem value="dr-smith">Dr. Smith</SelectItem>
                <SelectItem value="dr-jones">Dr. Jones</SelectItem>
              </SelectContent>
            </Select>
          </div>
          <div className="space-y-2">
            <Label htmlFor="dob" className="flex items-center gap-2">
              Date of Birth
              <Collapsible open={isMinorInfoOpen} onOpenChange={setIsMinorInfoOpen}>
                <CollapsibleTrigger asChild>
                  <Button variant="ghost" size="sm" className="h-8 w-8 p-0">
                    <Baby className="h-5 w-5 text-yellow-500" />
                    <ChevronRight className={`h-4 w-4 transition-transform ${isMinorInfoOpen ? 'rotate-90' : ''}`} />
                  </Button>
                </CollapsibleTrigger>
              </Collapsible>
            </Label>
            <Input id="dob" type="date" className="w-full" />
          </div>
          <div className="space-y-2">
            <Label htmlFor="source">Source</Label>
            <Select onValueChange={setSource}>
              <SelectTrigger id="source" className="bg-white">
                <SelectValue placeholder="Select source" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="web">Web</SelectItem>
                <SelectItem value="phone">Phone</SelectItem>
                <SelectItem value="referral">Referral</SelectItem>
                <SelectItem value="ad">Ad</SelectItem>
              </SelectContent>
            </Select>
          </div>
          {source === 'referral' && (
            <div className="space-y-2">
              <Label htmlFor="referralSource">Referral Source</Label>
              <Input id="referralSource" />
            </div>
          )}
        </div>
        
        <Collapsible open={isMinorInfoOpen} onOpenChange={setIsMinorInfoOpen}>
          <CollapsibleContent>
            <div className="mt-4 p-4 bg-gray-100 rounded-md space-y-4">
              <h4 className="font-semibold text-green-700">MINOR - RESPONSIBLE PARTY:</h4>
              <div className="grid grid-cols-2 gap-4">
                <div className="space-y-2">
                  <Label htmlFor="rpFirstName">First Name</Label>
                  <Input id="rpFirstName" />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="rpPhone">Phone</Label>
                  <Input id="rpPhone" type="tel" />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="rpLastName">Last Name</Label>
                  <Input id="rpLastName" />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="rpEmail">Email</Label>
                  <Input id="rpEmail" type="email" />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="rpDob">Date of Birth</Label>
                  <Input id="rpDob" type="date" />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="rpGender">Gender</Label>
                  <Select>
                    <SelectTrigger id="rpGender">
                      <SelectValue placeholder="Select gender" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="male">Male</SelectItem>
                      <SelectItem value="female">Female</SelectItem>
                      <SelectItem value="non-binary">Non-binary</SelectItem>
                      <SelectItem value="other">Other</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
                <div className="space-y-2">
                  <Label htmlFor="rpPronouns">Pronouns</Label>
                  <Select>
                    <SelectTrigger id="rpPronouns">
                      <SelectValue placeholder="Select pronouns" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="he/him">He/Him</SelectItem>
                      <SelectItem value="she/her">She/Her</SelectItem>
                      <SelectItem value="they/them">They/Them</SelectItem>
                      <SelectItem value="other">Other</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
              </div>
            </div>
          </CollapsibleContent>
        </Collapsible>

        <div className="flex items-center space-x-2">
          <Checkbox id="communications" />
          <Label htmlFor="communications">Turn off outbound communications</Label>
        </div>
        <RadioGroup defaultValue="open">
          <div className="flex items-center space-x-2">
            <RadioGroupItem value="open" id="open" />
            <Label htmlFor="open">Book First Session (Open Cal)</Label>
          </div>
          <div className="flex items-center space-x-2">
            <RadioGroupItem value="manual" id="manual" />
            <Label htmlFor="manual">Schedule Manually</Label>
          </div>
        </RadioGroup>
        <Button type="submit" className="w-full">Submit</Button>
      </form>
    </>
  );

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="outline" className="bg-white text-gray-800 hover:bg-gray-100 dark:bg-gray-800 dark:text-white dark:hover:bg-gray-700">
          <UserPlus className="mr-2 h-4 w-4" /> Add Patient
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[600px]">
        {modalContent}
      </DialogContent>
    </Dialog>
  );
}