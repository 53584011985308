import React, { useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { Home, FileText, Users, Settings, Search, User, ChevronUp, ChevronDown, X, Copy, MessageSquare, HelpCircle, Clock, FileSpreadsheet } from 'lucide-react';

const SearchModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="bg-white rounded-lg w-full max-w-2xl p-6">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">Search</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X className="h-6 w-6" />
          </button>
        </div>
        <input
          type="text"
          placeholder="Search..."
          className="w-full p-2 border border-gray-300 rounded mb-4"
        />
        <div>
          <h3 className="text-sm font-semibold text-gray-500 mb-2">Recent Searches</h3>
          <ul className="space-y-2">
            <li>
              <Link to="/patient-record/john-doe" className="text-blue-500 hover:underline">John Doe (Patient Record)</Link>
            </li>
            <li>
              <Link to="/document-manager/intake-forms" className="text-blue-500 hover:underline">Intake Forms (Document Manager)</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

const SideNav = () => {
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const [isSupportOpen, setIsSupportOpen] = useState(false);

  const toggleUserMenu = () => {
    setIsUserMenuOpen(!isUserMenuOpen);
  };

  const openSearchModal = () => {
    setIsSearchModalOpen(true);
  };

  const closeSearchModal = () => {
    setIsSearchModalOpen(false);
  };

  const toggleSupport = () => {
    setIsSupportOpen(!isSupportOpen);
  };

  return (
    <div className="flex h-screen">
      <nav className="bg-gray-800 text-white w-64 min-h-screen p-4 flex flex-col">
        <div className="mb-8">
          <h1 className="text-2xl font-bold">PsychAccess AI</h1>
        </div>
        <div className="mb-4">
          <button onClick={openSearchModal} className="flex items-center space-x-2 p-2 hover:bg-gray-700 rounded w-full">
            <Search className="h-5 w-5" />
            <span>Search</span>
          </button>
        </div>
        <ul className="space-y-2 flex-grow">
          <li>
            <Link to="/" className="flex items-center space-x-2 p-2 hover:bg-gray-700 rounded">
              <Home className="h-5 w-5" />
              <span>Dashboard</span>
            </Link>
          </li>
          <li>
            <Link to="/document-manager" className="flex items-center space-x-2 p-2 hover:bg-gray-700 rounded">
              <FileText className="h-5 w-5" />
              <span>Document Manager</span>
            </Link>
          </li>
          <li>
            <Link to="/patient-record" className="flex items-center space-x-2 p-2 hover:bg-gray-700 rounded">
              <Users className="h-5 w-5" />
              <span>Patient Record</span>
            </Link>
          </li>
          <li>
            <Link to="/settings" className="flex items-center space-x-2 p-2 hover:bg-gray-700 rounded">
              <Settings className="h-5 w-5" />
              <span>Settings</span>
            </Link>
          </li>
          <li>
            <Link to="/forms-library" className="flex items-center space-x-2 p-2 hover:bg-gray-700 rounded">
              <FileSpreadsheet className="h-5 w-5" />
              <span>Forms Library</span>
            </Link>
          </li>
        </ul>
        
        {/* Updated Support Section */}
        <div className="mb-4 p-4 bg-gray-700 rounded-lg border border-white">
          <button
            onClick={toggleSupport}
            className="flex items-center justify-between w-full text-left"
          >
            <h3 className="text-lg font-semibold">SUPPORT:</h3>
            {isSupportOpen ? <ChevronUp className="h-4 w-4" /> : <ChevronDown className="h-4 w-4" />}
          </button>
          <div className="flex items-center text-sm mt-2">
            <span>PIN: 3487</span>
            <button className="ml-2" onClick={() => navigator.clipboard.writeText('3487')}>
              <Copy className="h-4 w-4" />
            </button>
          </div>
          {isSupportOpen && (
            <div className="mt-2 space-y-2">
              <button className="w-full text-left p-2 hover:bg-gray-600 rounded flex items-center">
                <MessageSquare className="h-4 w-4 mr-2" />
                LIVE CHAT
              </button>
              <button className="w-full text-left p-2 hover:bg-gray-600 rounded flex items-center">
                <HelpCircle className="h-4 w-4 mr-2" />
                SUPPORT SITE
              </button>
              <button className="w-full text-left p-2 hover:bg-gray-600 rounded flex items-center">
                <Clock className="h-4 w-4 mr-2" />
                TICKET STATUS
              </button>
            </div>
          )}
        </div>

        <div className="mt-auto">
          <button
            onClick={toggleUserMenu}
            className="flex items-center justify-between w-full p-2 hover:bg-gray-700 rounded"
          >
            <div className="flex items-center space-x-2">
              <User className="h-5 w-5" />
              <span>Dr. Smith (ABC Clinic)</span>
            </div>
            {isUserMenuOpen ? <ChevronDown className="h-4 w-4" /> : <ChevronUp className="h-4 w-4" />}
          </button>
          {isUserMenuOpen && (
            <ul className="mt-2 space-y-1">
              <li>
                <button className="w-full text-left p-2 hover:bg-gray-700 rounded">Switch Company</button>
              </li>
              <li>
                <button className="w-full text-left p-2 hover:bg-gray-700 rounded">Logout</button>
              </li>
              <li>
                <button className="w-full text-left p-2 hover:bg-gray-700 rounded">Profile</button>
              </li>
            </ul>
          )}
        </div>
      </nav>
      <main className="flex-1 overflow-auto">
        <Outlet />
      </main>
      <SearchModal isOpen={isSearchModalOpen} onClose={closeSearchModal} />
    </div>
  );
};

export default SideNav;