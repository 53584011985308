import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from "../components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card";
import { Progress } from "../components/ui/progress";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../components/ui/select";
import { Checkbox } from "../components/ui/checkbox";
import { Label } from "../components/ui/label";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../components/ui/tabs";
import { CheckCircle, Clock, AlertCircle, Eye, Plus, Edit, Play, FileText, Calendar, Send, MessageSquare, Upload, User, AlertTriangle, BrainCircuit, Mail, Share2, Download, Printer, ExternalLink, X, GripVertical, Copy, ChevronDown, ChevronRight, Paperclip, Contact, Phone, UserPlus, Search, List, Grid, Filter } from 'lucide-react';
import { Input } from "../components/ui/input";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger, DialogFooter } from "../components/ui/dialog";
import { Textarea } from "../components/ui/textarea";
import { Switch } from "../components/ui/switch";
import { RadioGroup, RadioGroupItem } from "../components/ui/radio-group";

// Types
type SessionStatus = 'completed' | 'in-progress' | 'not-started' | 'scheduled';

type SessionItem = {
  id: string;
  title: string;
  status: SessionStatus;
  date?: string;
  isTitle?: boolean;
  children?: SessionItem[];
};

type ConsentForm = {
  id: string;
  title: string;
  status: 'completed' | 'not-completed';
};

type AssessmentItem = {
  id: string;
  title: string;
  status: 'complete' | 'in-progress' | 'not-started' | 'pending';
};

// Initial data
const initialSessions: SessionItem[] = [
  { id: '0', title: 'Consult Call', status: 'completed', date: '2023-04-15' },
  { id: '1', title: 'Intake Session', status: 'completed', date: '2023-05-01' },
  { 
    id: '2', 
    title: 'Testing Session 1', 
    isTitle: true,
    status: 'in-progress',
    children: [
      { id: '2-1', title: 'ADOS', status: 'not-started' },
      { id: '2-2', title: 'BAILEY', status: 'not-started' },
      { id: '2-3', title: 'KBIT-2', status: 'not-started' },
    ]
  },
  { 
    id: '3', 
    title: 'ASYNC Testing', 
    isTitle: true,
    status: 'in-progress',
    children: [
      { id: '3-1', title: 'ASYNC Test 003', status: 'not-started' },
    ]
  },
  { 
    id: '4', 
    title: 'Ad-Hoc', 
    isTitle: true,
    status: 'not-started',
    children: [
      { id: '4-1', title: 'School Observation', status: 'not-started' },
    ]
  },
  { id: '5', title: 'Feedback Session', status: 'not-started' },
  { id: '6', title: 'Report Delivery', status: 'not-started' },
];

const consentForms: ConsentForm[] = [
  { id: '1', title: 'Patient Consent Form', status: 'completed' },
  { id: '2', title: 'Intake Questionnaire', status: 'completed' },
  { id: '3', title: 'Release of Information', status: 'not-completed' },
];

const assessmentInventory: AssessmentItem[] = [
  { id: '1', title: 'Referral Letter', status: 'complete' },
  { id: '2', title: 'Connorsy Parent', status: 'complete' },
  { id: '3', title: 'Connorsy Teacher', status: 'in-progress' },
  { id: '4', title: 'KBIT-2 Results', status: 'complete' },
  { id: '5', title: 'SCARED Results', status: 'not-started' },
  { id: '6', title: 'VANDERBILT Results', status: 'not-started' },
  { id: '7', title: 'Classroom Observation Notes', status: 'pending' },
];

// Add this new array for AI Insights
const aiInsights = [
  { 
    id: '1', 
    content: "KBIT-2 results show a significant discrepancy between verbal comprehension and processing speed. Consider additional assessments for learning disabilities.",
    type: 'warning'
  },
  {
    id: '2',
    content: "Parent BASC scores are pending and may impact final diagnosis. Ensure completion before feedback session.",
    type: 'alert'
  },
  {
    id: '3',
    content: "Tyler's scores on sustained attention tasks improved with breaks. Consider recommending regular movement breaks in classroom.",
    type: 'info'
  },
];

// Helper components
const StatusIcon = ({ status }) => {
  switch (status) {
    case 'completed':
    case 'complete':
      return <CheckCircle className="h-5 w-5 text-green-500" />;
    case 'in-progress':
      return <Clock className="h-5 w-5 text-blue-500" />;
    case 'scheduled':
    case 'pending':
      return <Calendar className="h-5 w-5 text-yellow-500" />;
    default:
      return <AlertCircle className="h-5 w-5 text-gray-400" />;
  }
};

const QuickActionButton = ({ icon: Icon, label, onClick }) => (
  <Button 
    variant="outline" 
    className="flex flex-col items-center justify-center h-20 w-full border-2 border-black"
    onClick={onClick}
  >
    <Icon className="h-6 w-6 mb-1" />
    <span className="text-xs">{label}</span>
  </Button>
);

function CurrentAssessment() {
  const [sessions, setSessions] = useState(initialSessions);
  const [collapsedSections, setCollapsedSections] = useState({});

  const toggleSection = (id) => {
    setCollapsedSections(prev => ({...prev, [id]: !prev[id]}));
  };

  return (
    <Card className="mb-4">
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <CardTitle className="text-xl font-bold">Current Assessment</CardTitle>
        <Button size="sm">
          <Plus className="mr-2 h-4 w-4" /> Add Session
        </Button>
      </CardHeader>
      <CardContent className="pt-0">
        <div className="mb-2 flex items-center space-x-2">
          <span className="text-sm font-medium">Overall Progress:</span>
          <Progress value={65} className="w-64" />
          <span className="text-sm font-medium">65%</span>
        </div>
        <div className="space-y-4">
          {sessions.map(session => (
            <div key={session.id} className="bg-white border rounded-lg p-4 shadow-sm">
              <div className="flex items-center justify-between mb-2">
                <div className="flex items-center space-x-2">
                  {session.isTitle && (
                    <Button variant="ghost" size="sm" onClick={() => toggleSection(session.id)}>
                      {collapsedSections[session.id] ? <ChevronRight className="h-4 w-4" /> : <ChevronDown className="h-4 w-4" />}
                    </Button>
                  )}
                  <GripVertical className="h-5 w-5 text-gray-400" />
                  <span className={`font-medium ${session.isTitle ? 'text-lg' : ''}`}>
                    {session.title}
                    {(session.title === 'Consult Call' || session.title === 'Intake Session' || session.title === 'Feedback Session' || session.title === 'Report Delivery') && (
                      <span className="ml-2 text-sm text-gray-500">
                        (Dr. Berens) <Edit className="inline h-3 w-3 ml-1" />
                      </span>
                    )}
                    {session.title === 'Testing Session 1' && (
                      <span className="ml-2 text-sm text-gray-500">
                        (Clinician) <Edit className="inline h-3 w-3 ml-1" />
                      </span>
                    )}
                  </span>
                </div>
                {session.status === 'completed' ? (
                  <div className="flex items-center space-x-2">
                    <StatusIcon status={session.status} />
                    <Button variant="ghost" size="sm">
                      <Plus className="h-4 w-4" />
                    </Button>
                    <Button variant="ghost" size="sm">
                      <Eye className="h-4 w-4" />
                    </Button>
                  </div>
                ) : session.isTitle ? (
                  session.title === 'ASYNC Testing' ? (
                    <Button variant="ghost" size="sm">
                      <Send className="h-4 w-4" />
                    </Button>
                  ) : (
                    <Button variant="ghost" size="sm">
                      <Plus className="h-4 w-4" />
                    </Button>
                  )
                ) : session.title === 'Feedback Session' ? (
                  <div>
                    <Button variant="ghost" size="sm">
                      <Play className="h-4 w-4" />
                    </Button>
                    <Button variant="ghost" size="sm">
                      <Plus className="h-4 w-4" />
                    </Button>
                  </div>
                ) : session.title === 'Report Delivery' ? (
                  <div>
                    <Button variant="ghost" size="sm">
                      <FileText className="h-4 w-4" />
                    </Button>
                    <Button variant="ghost" size="sm">
                      <X className="h-4 w-4" />
                    </Button>
                  </div>
                ) : (
                  <StatusIcon status={session.status} />
                )}
              </div>
              {session.isTitle && !collapsedSections[session.id] && (
                <div className="ml-7 space-y-2">
                  {session.title === 'Ad-Hoc' ? (
                    <div className="text-sm text-gray-500">
                      Not scheduled
                      <Button variant="ghost" size="sm" className="ml-2">
                        <Calendar className="h-4 w-4" />
                      </Button>
                    </div>
                  ) : session.title === 'ASYNC Testing' ? (
                    <div className="text-sm text-gray-500">
                      Waiting For Patient
                      <Button variant="ghost" size="sm" className="ml-2">
                        <Calendar className="h-4 w-4" />
                      </Button>
                    </div>
                  ) : (
                    <div className="text-sm text-gray-500">
                      Scheduled: June 14th @ 9am
                      <Button variant="ghost" size="sm" className="ml-2">
                        <Calendar className="h-4 w-4" />
                      </Button>
                    </div>
                  )}
                  {session.children?.map(child => (
                    <div key={child.id} className="flex items-center justify-between">
                      <span>{child.title}</span>
                      <div>
                        <Button variant="ghost" size="sm">
                          <Play className="h-4 w-4" />
                        </Button>
                        <Button variant="ghost" size="sm">
                          <Plus className="h-4 w-4" />
                        </Button>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
}

function DocumentPreview() {
  const [reportView, setReportView] = useState('stakeholder');
  const [reportFormat, setReportFormat] = useState('full');

  return (
    <Card className="mb-4 bg-white">
      <CardHeader className="pb-2">
        <CardTitle className="text-xl font-bold">Document Preview</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="flex space-x-4">
          <div className="w-2/3">
            <div className="bg-gray-100 border border-gray-200 rounded-lg shadow-sm overflow-hidden mb-4">
              <img
                src="/images/Report-Preview.png"
                alt="Report Preview"
                className="w-full h-64 object-cover"
              />
            </div>
            <div className="flex justify-between">
              <Button variant="outline" size="sm">
                <Eye className="h-4 w-4 mr-2" />
                View
              </Button>
              <Button variant="outline" size="sm">
                <Edit className="h-4 w-4 mr-2" />
                Edit
              </Button>
            </div>
          </div>
          <div className="w-1/3 space-y-4">
            <div>
              <Label htmlFor="reportView">Stakeholder</Label>
              <Select value={reportView} onValueChange={setReportView}>
                <SelectTrigger id="reportView">
                  <SelectValue placeholder="Select view" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="stakeholder">Stakeholder</SelectItem>
                  <SelectItem value="parent">Parent</SelectItem>
                  <SelectItem value="child">Child</SelectItem>
                </SelectContent>
              </Select>
            </div>
            <div>
              <Label htmlFor="reportFormat">Report Type</Label>
              <Select value={reportFormat} onValueChange={setReportFormat}>
                <SelectTrigger id="reportFormat">
                  <SelectValue placeholder="Select format" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="full">Full Report</SelectItem>
                  <SelectItem value="summary">Summary</SelectItem>
                  <SelectItem value="cover">Cover Letter</SelectItem>
                </SelectContent>
              </Select>
            </div>
            <div className="flex space-x-2">
              <Button variant="outline" size="sm" className="flex-1">
                <Download className="h-4 w-4 mr-2" />
                Download
              </Button>
              <Button variant="outline" size="sm" className="flex-1">
                <Share2 className="h-4 w-4 mr-2" />
                Share
              </Button>
            </div>
            {/* New box with theme and type information */}
            <div className="border border-black rounded-lg p-4 mt-4">
              <div className="mb-2">
                <span className="font-semibold">Theme:</span> Custom
              </div>
              <div className="mb-4">
                <span className="font-semibold">Type:</span> Diagnostic
              </div>
              <Link to="/settings/myPreferences/myReports">
                <Button variant="outline" size="sm" className="w-full">
                  Report Settings
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

function AIInsights() {
  return (
    <Card className="mb-4">
      <CardHeader>
        <CardTitle className="text-xl font-bold">AI Insights</CardTitle>
      </CardHeader>
      <CardContent>
        <ul className="space-y-2">
          {aiInsights.map((insight) => (
            <li key={insight.id} className="bg-white dark:bg-gray-700 p-2 rounded-lg shadow-sm border border-gray-200 dark:border-gray-600">
              <div className="flex items-start space-x-2">
                {insight.type === 'info' && <BrainCircuit className="h-5 w-5 text-blue-500 mt-1" />}
                {insight.type === 'warning' && <AlertTriangle className="h-5 w-5 text-yellow-500 mt-1" />}
                {insight.type === 'alert' && <AlertCircle className="h-5 w-5 text-red-500 mt-1" />}
                <p className="text-sm">{insight.content}</p>
              </div>
            </li>
          ))}
        </ul>
      </CardContent>
    </Card>
  );
}

function PatientPortal() {
  // Add this new data for Audit Log and Document Activity
  const auditLog = [
    { id: 1, action: "Logged in", timestamp: "2024-05-01 09:30:15" },
    { id: 2, action: "Updated profile information", timestamp: "2024-05-01 09:35:22" },
    { id: 3, action: "Viewed assessment results", timestamp: "2024-05-01 10:15:07" },
    { id: 4, action: "Scheduled appointment", timestamp: "2024-05-01 11:20:33" },
    { id: 5, action: "Downloaded report", timestamp: "2024-05-02 14:45:19" },
  ];

  const documentActivity = [
    { id: 1, document: "Consent Form", action: "Signed", timestamp: "2024-04-30 15:20:11" },
    { id: 2, document: "Intake Questionnaire", action: "Submitted", timestamp: "2024-04-30 16:05:47" },
    { id: 3, document: "Assessment Report", action: "Viewed", timestamp: "2024-05-01 10:15:07" },
    { id: 4, document: "Treatment Plan", action: "Downloaded", timestamp: "2024-05-02 14:45:19" },
    { id: 5, document: "Progress Notes", action: "Accessed", timestamp: "2024-05-03 11:30:22" },
  ];

  return (
    <Card>
      <CardHeader>
        <CardTitle className="text-xl font-bold">Patient Portal</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          <div className="flex justify-between items-center">
            <span className="font-medium">Status:</span>
            <span className="text-green-500 font-medium">Active</span>
          </div>
          <div className="flex justify-between items-center">
            <span className="font-medium">Last Login:</span>
            <span>May 1, 2024</span>
          </div>
          <div className="space-y-2">
            <Button className="w-full">Manage Access</Button>
            <Button variant="outline" className="w-full">Login History</Button>
          </div>
          <div>
            <h4 className="font-medium mb-2">Permissions:</h4>
            <ul className="space-y-2">
              <li className="flex items-center space-x-2">
                <Checkbox id="schedule" />
                <Label htmlFor="schedule">Can schedule appointments</Label>
              </li>
              <li className="flex items-center space-x-2">
                <Checkbox id="view-docs" />
                <Label htmlFor="view-docs">Can view documents</Label>
              </li>
              <li className="flex items-center space-x-2">
                <Checkbox id="send-messages" />
                <Label htmlFor="send-messages">Can send messages</Label>
              </li>
            </ul>
          </div>
        </div>

        <div className="mt-8 grid grid-cols-2 gap-4">
          <div>
            <h3 className="text-lg font-semibold mb-2">Audit Log</h3>
            <div className="border rounded-lg overflow-hidden">
              <div className="max-h-60 overflow-y-auto">
                <table className="w-full">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
                      <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Timestamp</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {auditLog.map((log) => (
                      <tr key={log.id}>
                        <td className="px-4 py-2 whitespace-nowrap text-sm">{log.action}</td>
                        <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">{log.timestamp}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-2">Document Activity</h3>
            <div className="border rounded-lg overflow-hidden">
              <div className="max-h-60 overflow-y-auto">
                <table className="w-full">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Document</th>
                      <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
                      <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Timestamp</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {documentActivity.map((activity) => (
                      <tr key={activity.id}>
                        <td className="px-4 py-2 whitespace-nowrap text-sm">{activity.document}</td>
                        <td className="px-4 py-2 whitespace-nowrap text-sm">{activity.action}</td>
                        <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">{activity.timestamp}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

function TaskManager() {
  const [tasks, setTasks] = useState([
    { id: 1, text: 'Review patient history', status: 'todo', assignedTo: 'Me', dueDate: '2023-06-10' },
    { id: 2, text: 'Schedule follow-up appointment', status: 'todo', assignedTo: 'Dr. Smith', dueDate: '2023-06-15' },
    { id: 3, text: 'Complete assessment report', status: 'done', assignedTo: 'Me', dueDate: '2023-06-05' },
  ]);
  const [newTask, setNewTask] = useState('');
  const [isAdvancedOpen, setIsAdvancedOpen] = useState(false);
  const [assignedTo, setAssignedTo] = useState('Me');
  const [dueDate, setDueDate] = useState('');

  const addTask = (e) => {
    e.preventDefault();
    if (newTask.trim()) {
      setTasks([...tasks, { 
        id: tasks.length + 1, 
        text: newTask, 
        status: 'todo',
        assignedTo: assignedTo,
        dueDate: dueDate
      }]);
      setNewTask('');
      setAssignedTo('Me');
      setDueDate('');
      setIsAdvancedOpen(false);
    }
  };

  const toggleTaskStatus = (id) => {
    setTasks(tasks.map(task => 
      task.id === id ? { ...task, status: task.status === 'todo' ? 'done' : 'todo' } : task
    ));
  };

  return (
    <Card className="mb-4 bg-white">
      <CardHeader>
        <CardTitle className="text-xl font-bold">Task Manager</CardTitle>
      </CardHeader>
      <CardContent>
        <Tabs defaultValue="todo" className="w-full">
          <TabsList>
            <TabsTrigger value="todo">To Do</TabsTrigger>
            <TabsTrigger value="done">Done</TabsTrigger>
          </TabsList>
          <TabsContent value="todo">
            <ul className="space-y-2">
              {tasks.filter(task => task.status === 'todo').map(task => (
                <li key={task.id} className="flex items-center justify-between">
                  <div className="flex items-center space-x-2">
                    <Checkbox id={`task-${task.id}`} checked={false} onCheckedChange={() => toggleTaskStatus(task.id)} />
                    <Label htmlFor={`task-${task.id}`}>{task.text}</Label>
                  </div>
                  <div className="text-sm text-gray-500">
                    <span>{task.assignedTo}</span>
                    <span className="mx-2">|</span>
                    <span>{task.dueDate}</span>
                  </div>
                </li>
              ))}
            </ul>
          </TabsContent>
          <TabsContent value="done">
            <ul className="space-y-2">
              {tasks.filter(task => task.status === 'done').map(task => (
                <li key={task.id} className="flex items-center justify-between">
                  <div className="flex items-center space-x-2">
                    <Checkbox id={`task-${task.id}`} checked={true} onCheckedChange={() => toggleTaskStatus(task.id)} />
                    <Label htmlFor={`task-${task.id}`} className="line-through">{task.text}</Label>
                  </div>
                  <div className="text-sm text-gray-500">
                    <span>{task.assignedTo}</span>
                    <span className="mx-2">|</span>
                    <span>{task.dueDate}</span>
                  </div>
                </li>
              ))}
            </ul>
          </TabsContent>
        </Tabs>
        <div className="mt-4">
          <Dialog>
            <DialogTrigger asChild>
              <Button className="w-full">
                <Plus className="mr-2 h-4 w-4" /> Add Task
              </Button>
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Add New Task</DialogTitle>
              </DialogHeader>
              <form onSubmit={addTask} className="space-y-4">
                <div className="relative">
                  <Textarea
                    placeholder="Enter task"
                    value={newTask}
                    onChange={(e) => setNewTask(e.target.value)}
                    rows={3}
                    className="pr-10"
                  />
                  <Button
                    type="button"
                    variant="ghost"
                    size="sm"
                    className="absolute bottom-2 right-2"
                  >
                    <Paperclip className="h-4 w-4" />
                  </Button>
                </div>
                {isAdvancedOpen && (
                  <>
                    <Select value={assignedTo} onValueChange={setAssignedTo}>
                      <SelectTrigger>
                        <SelectValue placeholder="Assigned To" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="Me">Me</SelectItem>
                        <SelectItem value="Dr. Smith">Dr. Smith</SelectItem>
                        <SelectItem value="Nurse Johnson">Nurse Johnson</SelectItem>
                      </SelectContent>
                    </Select>
                    <Input
                      type="date"
                      value={dueDate}
                      onChange={(e) => setDueDate(e.target.value)}
                    />
                  </>
                )}
                <div className="flex justify-between">
                  <Button type="button" variant="outline" onClick={() => setIsAdvancedOpen(!isAdvancedOpen)}>
                    {isAdvancedOpen ? 'Simple' : 'Advanced'}
                  </Button>
                  <Button type="submit">Add Task</Button>
                </div>
              </form>
            </DialogContent>
          </Dialog>
        </div>
      </CardContent>
    </Card>
  );
}

const ContactModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Contact Information</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="flex items-center gap-4">
            <User className="h-5 w-5" />
            <span>Tyler Smith</span>
          </div>
          <div className="flex items-center gap-4">
            <Mail className="h-5 w-5" />
            <span>tyler.smith@example.com</span>
            <Button size="sm">
              Send Message
            </Button>
          </div>
          <div className="flex items-center gap-4">
            <Phone className="h-5 w-5" />
            <span>(555) 123-4567</span>
            <Button size="sm">
              Connect
            </Button>
          </div>
        </div>
        <DialogFooter>
          <Button onClick={onClose}>Close</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

// New components for Patient Profile and Stakeholders
function PatientProfile() {
  return (
    <Card className="bg-white">
      <CardHeader>
        <CardTitle className="text-xl font-bold">Patient Profile</CardTitle>
      </CardHeader>
      <CardContent>
        {/* Add patient profile content here */}
        <p>Patient profile information goes here.</p>
      </CardContent>
    </Card>
  );
}

function AddStakeholderModal({ isOpen, onClose }) {
  const [name, setName] = useState('');
  const [relationship, setRelationship] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [quickNotes, setQuickNotes] = useState('');
  const [accessEntireReport, setAccessEntireReport] = useState(false);
  const [accessStakeholderReport, setAccessStakeholderReport] = useState(false);
  const [stakeholderReportType, setStakeholderReportType] = useState('');
  const [releaseOfInformation, setReleaseOfInformation] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission
    onClose();
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[500px]">
        <DialogHeader>
          <DialogTitle>Add Stakeholder</DialogTitle>
        </DialogHeader>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="name">Name</Label>
            <Input
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Start typing to search or add new..."
            />
          </div>
          <div className="space-y-2">
            <Label htmlFor="relationship">Relationship</Label>
            <Select value={relationship} onValueChange={setRelationship}>
              <SelectTrigger id="relationship">
                <SelectValue placeholder="Select relationship" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="parent">Parent</SelectItem>
                <SelectItem value="teacher">Teacher</SelectItem>
                <SelectItem value="physician">Physician</SelectItem>
                <SelectItem value="therapist">Therapist</SelectItem>
                {/* Add more options as needed */}
              </SelectContent>
            </Select>
          </div>
          <div className="space-y-2">
            <Label htmlFor="phone">Phone</Label>
            <Input
              id="phone"
              type="tel"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
          <div className="space-y-2">
            <Label htmlFor="email">Email</Label>
            <Input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="space-y-2">
            <Label htmlFor="quickNotes">Quick Notes</Label>
            <Textarea
              id="quickNotes"
              value={quickNotes}
              onChange={(e) => setQuickNotes(e.target.value)}
            />
          </div>
          <div className="space-y-2">
            <Label>Access to Report</Label>
            <div className="flex items-center space-x-2">
              <Checkbox
                id="accessEntireReport"
                checked={accessEntireReport}
                onCheckedChange={setAccessEntireReport}
              />
              <Label htmlFor="accessEntireReport">Entire Report</Label>
            </div>
            <div className="flex items-center space-x-2">
              <Checkbox
                id="accessStakeholderReport"
                checked={accessStakeholderReport}
                onCheckedChange={setAccessStakeholderReport}
              />
              <Label htmlFor="accessStakeholderReport">Stakeholder Report</Label>
            </div>
            {accessStakeholderReport && (
              <Select value={stakeholderReportType} onValueChange={setStakeholderReportType}>
                <SelectTrigger id="stakeholderReportType">
                  <SelectValue placeholder="Select stakeholder report type" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="teacher">Teacher</SelectItem>
                  <SelectItem value="physician">Physician</SelectItem>
                  <SelectItem value="therapist">Therapist</SelectItem>
                  {/* Add more options as needed */}
                </SelectContent>
              </Select>
            )}
          </div>
          <Card className="bg-yellow-50 border-yellow-200">
            <CardContent className="p-4">
              <div className="flex items-center space-x-2 mb-2">
                <AlertTriangle className="h-5 w-5 text-yellow-500" />
                <span className="font-semibold">Release of Information</span>
              </div>
              <div className="flex items-center space-x-2">
                <Checkbox
                  id="releaseOfInformation"
                  checked={releaseOfInformation}
                  onCheckedChange={setReleaseOfInformation}
                />
                <Label htmlFor="releaseOfInformation">Release of Information already on file</Label>
              </div>
              {!releaseOfInformation && (
                <p className="text-sm text-yellow-600 mt-2">
                  If not checked, a release will be sent to the patient.
                </p>
              )}
            </CardContent>
          </Card>
          <DialogFooter>
            <Button type="submit">Add Stakeholder</Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
}

function Stakeholders() {
  const [viewMode, setViewMode] = useState('list');
  const [selectedStakeholder, setSelectedStakeholder] = useState(null);

  const stakeholders = [
    { id: 1, name: 'John Doe', relationship: 'Father', category: 'Family Members', phone: '(555) 123-4567', email: 'john@example.com', portalAccess: 'Active', lastContact: '2023-05-15' },
    { id: 2, name: 'Jane Smith', relationship: 'Teacher', category: 'Educational Professionals', phone: '(555) 987-6543', email: 'jane@example.com', portalAccess: 'Inactive', lastContact: '2023-05-10' },
    // Add more stakeholders as needed
  ];

  const activeRelationships = [
    { id: 1, name: 'Alex Johnson', assessment: 'ADHD Assessment', status: 'Completed', date: '2023-04-15' },
    { id: 2, name: 'Emma Brown', assessment: 'Anxiety Assessment', status: 'In Progress', date: '2023-05-20' },
    { id: 3, name: 'Michael Lee', assessment: 'Depression Assessment', status: 'Scheduled', date: '2023-06-10' },
  ];

  const [globalAccess, setGlobalAccess] = useState(false);
  const [localAccessType, setLocalAccessType] = useState('entire');
  const [localAccessReport, setLocalAccessReport] = useState('teacher');

  const [isAddStakeholderModalOpen, setIsAddStakeholderModalOpen] = useState(false);

  return (
    <div className="flex gap-4">
      <div className="w-2/3 space-y-4">
        <Card className="bg-white">
          <CardHeader className="flex flex-row items-center justify-between">
            <CardTitle className="text-xl font-bold">Stakeholders</CardTitle>
            <div className="flex items-center space-x-2">
              <Button variant="outline" size="sm" onClick={() => setViewMode('list')}>
                <List className="h-4 w-4" />
              </Button>
              <Button variant="outline" size="sm" onClick={() => setViewMode('grid')}>
                <Grid className="h-4 w-4" />
              </Button>
              <Button variant="outline" size="sm">
                <Filter className="h-4 w-4" />
              </Button>
              <Input type="text" placeholder="Search stakeholders" className="w-40" />
            </div>
          </CardHeader>
          <CardContent>
            {viewMode === 'list' ? (
              <ul className="space-y-2">
                {stakeholders.map((stakeholder) => (
                  <li key={stakeholder.id} className="flex items-center justify-between p-2 hover:bg-gray-100 rounded-md">
                    <div>
                      <span className="font-medium">{stakeholder.name}</span>
                      <span className="text-sm text-gray-500 ml-2">({stakeholder.relationship})</span>
                    </div>
                    <div className="flex items-center space-x-2">
                      <Button variant="ghost" size="sm">
                        <MessageSquare className="h-4 w-4" />
                      </Button>
                      <Button variant="ghost" size="sm">
                        <Edit className="h-4 w-4" />
                      </Button>
                      <Button variant="ghost" size="sm">
                        <Send className="h-4 w-4" />
                      </Button>
                      <Button variant="ghost" size="sm" onClick={() => setSelectedStakeholder(stakeholder)}>
                        <ChevronRight className="h-4 w-4" />
                      </Button>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <div className="grid grid-cols-3 gap-4">
                {stakeholders.map((stakeholder) => (
                  <Card key={stakeholder.id} className="p-4">
                    <h3 className="font-medium">{stakeholder.name}</h3>
                    <p className="text-sm text-gray-500">{stakeholder.relationship}</p>
                    <p className="text-sm">{stakeholder.category}</p>
                    <Button variant="ghost" size="sm" className="mt-2" onClick={() => setSelectedStakeholder(stakeholder)}>
                      View Details
                    </Button>
                  </Card>
                ))}
              </div>
            )}
          </CardContent>
        </Card>
      </div>
      <div className="w-1/3 space-y-4">
        <Card className="mb-4 bg-white">
          <CardHeader>
            <CardTitle className="text-xl font-bold">Quick Actions</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="grid grid-cols-2 gap-2">
              <QuickActionButton
                icon={UserPlus}
                label="Add Stakeholder"
                onClick={() => setIsAddStakeholderModalOpen(true)}
              />
              <QuickActionButton icon={Mail} label="Send Email" />
              <QuickActionButton icon={Phone} label="Call" />
              <QuickActionButton icon={FileText} label="Generate Report" />
            </div>
          </CardContent>
        </Card>
        <Card className="bg-white">
          <CardHeader>
            <CardTitle className="text-lg font-bold">Recent Activity</CardTitle>
          </CardHeader>
          <CardContent>
            <ul className="space-y-2">
              <li className="text-sm">Added new stakeholder: Jane Smith</li>
              <li className="text-sm">Updated permissions for John Doe</li>
              <li className="text-sm">Sent form to Dr. Johnson</li>
            </ul>
          </CardContent>
        </Card>
      </div>
      {selectedStakeholder && (
        <Dialog open={!!selectedStakeholder} onOpenChange={() => setSelectedStakeholder(null)}>
          <DialogContent className="sm:max-w-[600px]">
            <DialogHeader>
              <DialogTitle className="text-2xl font-bold">{selectedStakeholder.name}</DialogTitle>
            </DialogHeader>
            <div className="grid gap-6 py-4">
              <Card>
                <CardHeader>
                  <CardTitle className="text-lg font-semibold">Basic Information</CardTitle>
                </CardHeader>
                <CardContent className="grid grid-cols-2 gap-4">
                  <div>
                    <Label>Relationship</Label>
                    <p className="text-sm">{selectedStakeholder.relationship}</p>
                  </div>
                  <div>
                    <Label>Category</Label>
                    <p className="text-sm">{selectedStakeholder.category}</p>
                  </div>
                  <div>
                    <Label>Phone</Label>
                    <p className="text-sm">{selectedStakeholder.phone}</p>
                  </div>
                  <div>
                    <Label>Email</Label>
                    <p className="text-sm">{selectedStakeholder.email}</p>
                  </div>
                </CardContent>
              </Card>

              <Card>
                <CardHeader>
                  <CardTitle className="text-lg font-semibold">Active Relationships</CardTitle>
                </CardHeader>
                <CardContent>
                  <ul className="space-y-2">
                    {activeRelationships.map((relationship) => (
                      <li key={relationship.id} className="flex items-center justify-between bg-gray-50 p-2 rounded-md">
                        <div>
                          <span className="font-medium">{relationship.name}</span>
                          <span className="text-sm text-gray-500 ml-2">({relationship.assessment})</span>
                        </div>
                        <div className="flex items-center space-x-2">
                          <span className={`text-sm ${relationship.status === 'Completed' ? 'text-green-500' : 'text-yellow-500'}`}>
                            {relationship.status}
                          </span>
                          <span className="text-sm text-gray-500">{relationship.date}</span>
                          <Button variant="ghost" size="sm">
                            <ChevronRight className="h-4 w-4" />
                          </Button>
                        </div>
                      </li>
                    ))}
                  </ul>
                </CardContent>
              </Card>

              <Card>
                <CardHeader>
                  <CardTitle className="text-lg font-semibold">Portal Access</CardTitle>
                </CardHeader>
                <CardContent className="space-y-4">
                  <div>
                    <h4 className="text-sm font-medium mb-2">Global Access</h4>
                    <div className="flex items-center space-x-2">
                      <Switch
                        checked={globalAccess}
                        onCheckedChange={setGlobalAccess}
                        id="global-access"
                      />
                      <Label htmlFor="global-access">
                        {globalAccess ? 'Enabled' : 'Disabled'}
                      </Label>
                    </div>
                  </div>
                  {/* Replace Separator with a border */}
                  <div className="border-t border-gray-200 pt-4">
                    <h4 className="text-sm font-medium mb-2">Local Access</h4>
                    <div className="space-y-2">
                      <div className="flex items-center space-x-2">
                        <Checkbox id="entire-report" checked={localAccessType === 'entire'} onCheckedChange={() => setLocalAccessType('entire')} />
                        <Label htmlFor="entire-report">Access Entire Report</Label>
                      </div>
                      <div className="flex items-center space-x-2">
                        <Checkbox id="specific-report" checked={localAccessType === 'specific'} onCheckedChange={() => setLocalAccessType('specific')} />
                        <Label htmlFor="specific-report">Access Specific Report</Label>
                      </div>
                    </div>
                    {localAccessType === 'specific' && (
                      <Select value={localAccessReport} onValueChange={setLocalAccessReport} className="mt-2">
                        <SelectTrigger>
                          <SelectValue placeholder="Select report type" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="teacher">Teacher Report</SelectItem>
                          <SelectItem value="physician">Physician Report</SelectItem>
                          <SelectItem value="iep">IEP Report</SelectItem>
                        </SelectContent>
                      </Select>
                    )}
                  </div>
                </CardContent>
              </Card>
            </div>
            <DialogFooter>
              <Button onClick={() => setSelectedStakeholder(null)}>Close</Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      )}
      <AddStakeholderModal
        isOpen={isAddStakeholderModalOpen}
        onClose={() => setIsAddStakeholderModalOpen(false)}
      />
    </div>
  );
}

export default function PatientRecord() {
  const [activeTab, setActiveTab] = useState("active-assessment");
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);

  const menuItems = [
    { id: "active-assessment", label: "Active Assessment" },
    { id: "patient-portal", label: "Patient Portal" },
    { id: "patient-profile", label: "Patient Profile" },
    { id: "stakeholders", label: "Stakeholders" },
  ];

  const [hasReleaseOfInformation, setHasReleaseOfInformation] = useState(false);

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900">
      <header className="bg-white dark:bg-gray-800 shadow">
        <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
          <div className="flex items-center">
            <div>
              <h1 className="text-2xl font-bold text-gray-900 dark:text-white flex items-center">
                Tyler Smith
                <User className="h-5 w-5 text-gray-500 ml-2" />
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => setIsContactModalOpen(true)}
                  className="ml-2"
                >
                  <Contact className="h-5 w-5" />
                </Button>
              </h1>
              <span className="text-sm text-gray-500">
                [Acct#: 1077544] <Copy className="inline h-4 w-4 cursor-pointer" />
              </span>
            </div>
          </div>
          <div className="text-right">
            <p className="text-sm text-gray-500 dark:text-gray-400">Age: 9</p>
            <p className="text-sm font-medium">[Active]ADHD Assessment (Child)</p>
            <p className={`text-sm ${hasReleaseOfInformation ? 'text-green-500' : 'text-red-500'}`}>
              {hasReleaseOfInformation ? 'Release of Information on File' : 'No Release of Information'}
            </p>
          </div>
        </div>
      </header>

      <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="mb-6">
          <nav className="flex space-x-4">
            {menuItems.map((item) => (
              <button
                key={item.id}
                onClick={() => setActiveTab(item.id)}
                className={`px-3 py-2 rounded-md text-sm font-medium ${
                  activeTab === item.id
                    ? "bg-blue-500 text-white"
                    : "text-gray-700 hover:bg-gray-200"
                }`}
              >
                {item.label}
              </button>
            ))}
          </nav>
        </div>

        {activeTab === "active-assessment" && (
          <div className="flex gap-4">
            <div className="w-2/3 space-y-4">
              <Card className="bg-white">
                <CardContent className="p-4">
                  <CurrentAssessment />
                </CardContent>
              </Card>
              <DocumentPreview />
              <Card className="bg-white">
                <CardContent className="p-4">
                  <AIInsights />
                </CardContent>
              </Card>
            </div>
            <div className="w-1/3 space-y-4">
              <Card className="mb-4 bg-white">
                <CardHeader>
                  <CardTitle className="text-xl font-bold">Quick Actions</CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="grid grid-cols-2 gap-2">
                    <QuickActionButton icon={Plus} label="Add Service" />
                    <QuickActionButton icon={Send} label="Send Form" />
                    <QuickActionButton icon={MessageSquare} label="Message" />
                    <QuickActionButton icon={Calendar} label="Schedule" />
                    <QuickActionButton icon={Upload} label="Upload" />
                    <QuickActionButton icon={FileText} label="Notes" />
                  </div>
                </CardContent>
              </Card>
              <TaskManager />
              <Card className="mb-4 bg-white">
                <CardHeader>
                  <CardTitle className="text-xl font-bold">Consent and Intake Forms</CardTitle>
                </CardHeader>
                <CardContent>
                  <ul className="space-y-2">
                    {consentForms.map((form) => (
                      <li key={form.id} className="flex items-center justify-between">
                        <div className="flex items-center space-x-2">
                          <Checkbox id={form.id} checked={form.status === 'completed'} />
                          <Label htmlFor={form.id}>{form.title}</Label>
                        </div>
                        {form.status === 'not-completed' && (
                          <Button variant="ghost" size="sm">
                            <Mail className="h-4 w-4 mr-1" /> Send
                          </Button>
                        )}
                      </li>
                    ))}
                  </ul>
                </CardContent>
              </Card>
              <Card className="bg-white">
                <CardHeader>
                  <CardTitle className="text-xl font-bold">Assessment Assets</CardTitle>
                </CardHeader>
                <CardContent>
                  <ul className="space-y-2">
                    {assessmentInventory.map((item) => (
                      <li key={item.id} className="flex items-center justify-between">
                        <div className="flex items-center space-x-2">
                          <StatusIcon status={item.status} />
                          <span>{item.title}</span>
                        </div>
                        <Button variant="ghost" size="sm">
                          <FileText className="h-4 w-4" />
                        </Button>
                      </li>
                    ))}
                  </ul>
                </CardContent>
              </Card>
            </div>
          </div>
        )}

        {activeTab === "patient-portal" && (
          <Card className="bg-white">
            <CardContent className="p-4">
              <PatientPortal />
            </CardContent>
          </Card>
        )}

        {activeTab === "patient-profile" && (
          <div className="flex gap-4">
            <div className="w-2/3 space-y-4">
              <PatientProfile />
            </div>
            <div className="w-1/3 space-y-4">
              <Card className="mb-4 bg-white">
                <CardHeader>
                  <CardTitle className="text-xl font-bold">Quick Actions</CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="grid grid-cols-2 gap-2">
                    <QuickActionButton icon={Edit} label="Edit Profile" />
                    <QuickActionButton icon={Upload} label="Upload Document" />
                    <QuickActionButton icon={MessageSquare} label="Send Message" />
                    <QuickActionButton icon={Calendar} label="Schedule Appointment" />
                  </div>
                </CardContent>
              </Card>
              <TaskManager />
            </div>
          </div>
        )}

        {activeTab === "stakeholders" && (
          <Stakeholders />
        )}
      </main>

      <ContactModal
        isOpen={isContactModalOpen}
        onClose={() => setIsContactModalOpen(false)}
      />
    </div>
  );
}