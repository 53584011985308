import React, { useState } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card";
import { Button } from "../components/ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../components/ui/dialog";
import { ScrollArea } from "../components/ui/scroll-area";
import { Eye, ChevronRight, FileText } from 'lucide-react';
import { Link } from 'react-router-dom';

const initialQueuedDocuments = [
  { id: '1', fileName: 'patient_record.pdf', dateTime: '2023-09-15 10:30', uploader: 'Dr. Smith' },
  { id: '2', fileName: 'lab_results.docx', dateTime: '2023-09-14 15:45', uploader: 'Nurse Johnson' },
  { id: '3', fileName: 'prescription.pdf', dateTime: '2023-09-13 09:00', uploader: 'Dr. Brown' },
];

export function DocumentQueueTile() {
  const [queuedDocuments] = useState(initialQueuedDocuments);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [isQuickViewOpen, setIsQuickViewOpen] = useState(false);

  const handleQuickView = (document) => {
    setSelectedDocument(document);
    setIsQuickViewOpen(true);
  };

  return (
    <Card>
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <CardTitle className="text-lg font-bold">Documents</CardTitle>
        <Link to="/document-manager">
          <Button variant="ghost" size="sm" className="h-8 w-8 p-0">
            <ChevronRight className="h-4 w-4" />
          </Button>
        </Link>
      </CardHeader>
      <CardContent className="bg-white p-4 rounded-b-lg">
        {queuedDocuments.length > 0 ? (
          <ScrollArea className="h-[120px] w-full">
            {queuedDocuments.slice(0, 2).map((doc) => (
              <div key={doc.id} className="flex items-center justify-between py-2 border-b last:border-b-0">
                <div className="flex items-center space-x-2">
                  <FileText className="h-4 w-4 text-gray-500" />
                  <div>
                    <p className="text-sm font-medium">{doc.fileName}</p>
                    <p className="text-xs text-gray-500">{doc.dateTime}</p>
                  </div>
                </div>
                <Button variant="ghost" size="sm" className="h-6 w-6 p-0" onClick={() => handleQuickView(doc)}>
                  <Eye className="h-3 w-3" />
                  <span className="sr-only">Quick view</span>
                </Button>
              </div>
            ))}
          </ScrollArea>
        ) : (
          <div className="text-center">
            <div className="text-3xl font-bold text-gray-300 dark:text-gray-600">--</div>
            <p className="text-xs text-gray-500 dark:text-gray-400">No documents</p>
          </div>
        )}
      </CardContent>

      <Dialog open={isQuickViewOpen} onOpenChange={setIsQuickViewOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Document Quick View</DialogTitle>
          </DialogHeader>
          {selectedDocument && (
            <div className="space-y-4">
              <div>
                <p><strong>File Name:</strong> {selectedDocument.fileName}</p>
                <p><strong>Date & Time:</strong> {selectedDocument.dateTime}</p>
                <p><strong>Uploader:</strong> {selectedDocument.uploader}</p>
              </div>
              <div className="flex justify-end space-x-2">
                <Button variant="outline" onClick={() => setIsQuickViewOpen(false)}>
                  Close
                </Button>
                <Link to="/document-manager">
                  <Button>
                    Go to Document Manager
                  </Button>
                </Link>
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </Card>
  );
}