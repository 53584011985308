import React, { useState } from 'react';
import { Plus, UserPlus, Play, Search } from "lucide-react";
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import AddDataModal from "./AddDataModal";
import PatientIntakeModal from "./PatientIntakeModal";
import { StartSessionModal } from './StartSessionModal';

function QuickLaunchBar() {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleStartSession = (patient, step) => {
    console.log(`Starting session for ${patient.name} at step: ${step}`);
    // Add your logic here to start the session
  };

  return (
    <section className="mb-8 bg-gradient-to-r from-blue-50 to-purple-50 p-4 rounded-lg shadow-sm dark:from-blue-900 dark:to-purple-900">
      <div className="flex space-x-4">
        <div className="relative">
          <Button 
            variant="outline" 
            className="bg-white text-gray-800 hover:bg-gray-100 dark:bg-gray-800 dark:text-white dark:hover:bg-gray-700"
            onClick={() => setIsSearchOpen(!isSearchOpen)}
          >
            <Search className="h-4 w-4" />
          </Button>
          {isSearchOpen && (
            <Input
              type="text"
              placeholder="Search..."
              className="absolute top-full left-0 mt-2 w-64"
              autoFocus
            />
          )}
        </div>
        <AddDataModal />
        <PatientIntakeModal />
        <Button 
          variant="outline" 
          className="bg-white text-gray-800 hover:bg-gray-100 dark:bg-gray-800 dark:text-white dark:hover:bg-gray-700"
          onClick={() => setIsModalOpen(true)}
        >
          <Play className="mr-2 h-4 w-4" /> Start Session
        </Button>
      </div>
      <StartSessionModal 
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onStartSession={handleStartSession}
      />
    </section>
  );
}

export default QuickLaunchBar;