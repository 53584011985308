import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import SideNav from './components/SideNav';
import Dashboard from './pages/Dashboard';
import DocumentManager from './pages/DocumentManager';
import PatientRecord from './pages/PatientRecord';
import Settings from './pages/Settings';
import FormsLibrary from './pages/FormsLibrary';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<SideNav />}>
          <Route index element={<Dashboard />} />
          <Route path="document-manager" element={<DocumentManager />} />
          <Route path="patient-record" element={<PatientRecord />} />
          <Route path="settings" element={<Settings />}>
            <Route index element={<Navigate to="communications/domains" replace />} />
            <Route path=":category/:setting" element={<Settings />} />
          </Route>
          <Route path="/forms-library" element={<FormsLibrary />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;