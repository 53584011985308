import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Input } from "../components/ui/input"
import { Button } from "../components/ui/button"
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card"
import { Checkbox } from "../components/ui/checkbox"
import { Label } from "../components/ui/label"
import { RadioGroup, RadioGroupItem } from "../components/ui/radio-group"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../components/ui/select"
import { Textarea } from "../components/ui/textarea"
import { Search, ChevronRight, Save, X, Eye, InfoIcon, PlusCircle } from 'lucide-react'
import { ScrollArea } from "../components/ui/scroll-area"
import { Switch } from "../components/ui/switch"
import { Link } from 'react-router-dom'

const settingsData = {
  myPreferences: {
    myReports: {
      title: 'My Reports',
      content: 'Configure your personal report settings.'
    },
    myAI: {
      title: 'My AI',
      content: 'Configure your AI settings.'
    },
    myCommunications: {
      title: 'My Communications',
      content: 'Set up your personal communication preferences.'
    },
    myCalendar: {
      title: 'My Calendar',
      content: 'Customize your calendar settings.'
    },
    dataManagement: {
      title: 'Data Management',
      content: 'Manage your data fields and preferences.'
    }
  },
  communications: {
    domains: {
      title: 'Domains',
      content: 'Add your custom domain here.'
    },
    emails: {
      title: 'Emails',
      content: 'Manage your email settings and templates.'
    },
    patientPortal: {
      title: 'Patient Portal',
      content: 'Customize your patient portal settings.'
    }
  },
  billing: {
    paymentMethods: {
      title: 'Payment Methods',
      content: 'Set up and manage payment methods.'
    },
    invoices: {
      title: 'Invoices',
      content: 'Configure invoice settings and templates.'
    }
  },
  security: {
    authentication: {
      title: 'Authentication',
      content: 'Manage authentication methods and settings.'
    },
    dataEncryption: {
      title: 'Data Encryption',
      content: 'Configure data encryption settings.'
    },
    accessControl: {
      title: 'Access Control',
      content: 'Set up user roles and permissions.'
    }
  },
  notifications: {
    emailNotifications: {
      title: 'Email Notifications',
      content: 'Configure email notification preferences.'
    },
    smsNotifications: {
      title: 'SMS Notifications',
      content: 'Set up SMS notification settings.'
    }
  }
}

export default function SettingsPage() {
  const { category, setting } = useParams()
  const navigate = useNavigate()
  const [selectedCategory, setSelectedCategory] = useState(category || 'communications')
  const [selectedSetting, setSelectedSetting] = useState(setting || 'domains')
  const [hasChanges, setHasChanges] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [oxfordComma, setOxfordComma] = useState("")
  const [wordChoice, setWordChoice] = useState("")
  const [uploadMethod, setUploadMethod] = useState("upload")
  const [bookingSlug, setBookingSlug] = useState("name")
  const [useCustomDomain, setUseCustomDomain] = useState(false)
  const [customDomain, setCustomDomain] = useState("")
  const [questions, setQuestions] = useState([]);
  const [showAddQuestion, setShowAddQuestion] = useState(false);
  const [newQuestion, setNewQuestion] = useState({
    questionName: '',
    questionText: '',
    answerType: 'freeText',
    isClinical: false,
  });

  useEffect(() => {
    if (category && setting) {
      setSelectedCategory(category)
      setSelectedSetting(setting)
    }
  }, [category, setting])

  const handleChange = () => {
    setHasChanges(true)
  }

  const handleSave = () => {
    // Implement save logic here
    console.log('Changes saved')
    setHasChanges(false)
  }

  const handleDiscard = () => {
    // Implement discard changes logic here
    console.log('Changes discarded')
    setHasChanges(false)
  }

  const handleCategoryChange = (newCategory, newSetting) => {
    setSelectedCategory(newCategory)
    setSelectedSetting(newSetting)
    navigate(`/settings/${newCategory}/${newSetting}`)
  }

  const handleAddQuestion = () => {
    const newQuestionWithId = {
      ...newQuestion,
      uid: questions.length + 1,
    };
    setQuestions([...questions, newQuestionWithId]);
    setNewQuestion({
      questionName: '',
      questionText: '',
      answerType: 'freeText',
      isClinical: false,
    });
    setShowAddQuestion(false);
  };

  const filteredSettings = Object.entries(settingsData).reduce((acc, [category, subItems]) => {
    const filteredSubItems = Object.entries(subItems).filter(([key, { title }]) => 
      title.toLowerCase().includes(searchQuery.toLowerCase())
    )
    if (filteredSubItems.length > 0) {
      acc[category] = Object.fromEntries(filteredSubItems)
    }
    return acc
  }, {})

  const renderContent = () => {
    if (selectedCategory === 'myPreferences') {
      return (
        <div>
          <div className="bg-blue-100 p-4 mb-6 rounded">
            These settings apply only to you
          </div>
          {selectedSetting === 'myReports' && (
            <div className="space-y-6">
              <div>
                <Label htmlFor="reportsTheme">Reports Theme</Label>
                <Select defaultValue="useGlobal">
                  <SelectTrigger id="reportsTheme">
                    <SelectValue placeholder="Select theme" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="useGlobal">Use Global</SelectItem>
                    <SelectItem value="theme1">Theme 1</SelectItem>
                    <SelectItem value="theme2">Theme 2</SelectItem>
                  </SelectContent>
                </Select>
                <div className="mt-2 p-2 bg-gray-100 rounded flex justify-between items-center">
                  <p>Global: Modern</p>
                  <Button variant="ghost" size="sm">
                    <Eye className="h-4 w-4" />
                    Preview
                  </Button>
                </div>
                <Button variant="link" className="mt-2">Create Custom Theme</Button>
              </div>
              <div>
                <Label htmlFor="reportsColorGuide">Reports Color Guide</Label>
                <Select defaultValue="useGlobal">
                  <SelectTrigger id="reportsColorGuide">
                    <SelectValue placeholder="Select color family" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="useGlobal">Use Global</SelectItem>
                    <SelectItem value="family1">Color Family 1</SelectItem>
                    <SelectItem value="family2">Color Family 2</SelectItem>
                  </SelectContent>
                </Select>
                <div className="mt-2 p-2 bg-gray-100 rounded flex justify-between items-center">
                  <p>Global: Neutral</p>
                  <Button variant="ghost" size="sm">
                    <Eye className="h-4 w-4" />
                    Preview
                  </Button>
                </div>
                <Button variant="link" className="mt-2">Create Custom Colors</Button>
              </div>
              <div>
                <Label htmlFor="reportType">Report Type</Label>
                <Select defaultValue="diagnostic">
                  <SelectTrigger id="reportType">
                    <SelectValue placeholder="Select report type" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="diagnostic">Diagnostic</SelectItem>
                    <SelectItem value="measurement">Measurement</SelectItem>
                  </SelectContent>
                </Select>
              </div>
              <div>
                <h3 className="font-semibold mb-2">How your data will show on report:</h3>
                <div className="space-y-2">
                  <div>
                    <Label htmlFor="name">Name</Label>
                    <Input id="name" defaultValue="John Doe" />
                  </div>
                  <div>
                    <Label htmlFor="email">Email</Label>
                    <Input id="email" defaultValue="john.doe@example.com" />
                  </div>
                  <div>
                    <Label htmlFor="address">Address</Label>
                    <Select defaultValue="globalAddress">
                      <SelectTrigger id="address">
                        <SelectValue placeholder="Select address" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="globalAddress">Global Address</SelectItem>
                        <SelectItem value="addNew">Add New Address</SelectItem>
                      </SelectContent>
                    </Select>
                    <div className="mt-2 p-2 bg-gray-100 rounded">
                      <p>123 Global St, City, State, 12345</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {selectedSetting === 'myAI' && (
            <div className="space-y-8">
              <Card>
                <CardHeader>
                  <CardTitle className="text-3xl font-bold text-primary">Personalize Your AI Assistant</CardTitle>
                </CardHeader>
                <CardContent className="space-y-8">
                  <div className="bg-blue-50 border-blue-200 border p-4 rounded-md">
                    <p className="text-blue-800">
                      <strong>AI Statement:</strong> The use of AI, the sharing of patient information and the leverage of AI models to better predict, write and recommend is done under strict HIPAA compliance. Your content is not only protected with strict adherence to ISO27001 but also walled so it is only available and training you. AI training is NOT clinic wide.
                    </p>
                  </div>

                  <p className="text-gray-600 leading-relaxed">
                    Help your AI assistant understand your unique approach to assessments, writing style, and client interactions. This process will enhance the AI's ability to provide relevant support tailored to your professional style.
                  </p>

                  <section>
                    <h2 className="text-2xl font-semibold mb-4 text-primary">1. Upload Sample Documents</h2>
                    <p className="mb-4 text-gray-600">
                      Provide anonymized examples of your work to help the AI understand your style. This could include assessment reports, clinical notes, or other relevant documents.
                    </p>
                    <RadioGroup onValueChange={setUploadMethod} value={uploadMethod} className="mb-4">
                      <div className="flex items-center space-x-2">
                        <RadioGroupItem value="upload" id="upload-method" />
                        <Label htmlFor="upload-method">Upload files directly</Label>
                      </div>
                      <div className="flex items-center space-x-2">
                        <RadioGroupItem value="cloud" id="cloud-method" />
                        <Label htmlFor="cloud-method">Define a folder in your cloud drive</Label>
                      </div>
                    </RadioGroup>
                    {uploadMethod === "upload" ? (
                      <div className="flex items-center justify-center w-full">
                        <label htmlFor="dropzone-file" className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100 transition duration-300">
                          <div className="flex flex-col items-center justify-center pt-5 pb-6">
                            <svg className="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path>
                            </svg>
                            <p className="mb-2 text-sm text-gray-500"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                            <p className="text-xs text-gray-500">PDF, DOCX, or TXT (Max 5 files)</p>
                          </div>
                          <input id="dropzone-file" type="file" className="hidden" multiple />
                        </label>
                      </div>
                    ) : (
                      <div>
                        <Label htmlFor="cloud-folder" className="font-medium">Cloud Folder Path</Label>
                        <Input id="cloud-folder" placeholder="Enter the path to your cloud folder containing reports" className="mt-1" />
                      </div>
                    )}
                  </section>

                  {/* Writing Style Preferences */}
                  <section>
                    <h2 className="text-2xl font-semibold mb-4 text-primary">2. Writing Style Preferences</h2>
                    <p className="mb-4 text-gray-600">
                      These questions will help refine the AI's understanding of your writing preferences and style.
                    </p>
                    <div className="space-y-4">
                      <div>
                        <Label htmlFor="writing-tone" className="font-medium">
                          How would you characterize your writing tone in professional documents?
                        </Label>
                        <Select>
                          <SelectTrigger id="writing-tone">
                            <SelectValue placeholder="Select your writing tone" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value="formal">Formal and academic</SelectItem>
                            <SelectItem value="professional">Professional but approachable</SelectItem>
                            <SelectItem value="conversational">Conversational and empathetic</SelectItem>
                            <SelectItem value="direct">Direct and concise</SelectItem>
                            <SelectItem value="adaptable">Adaptable based on the audience</SelectItem>
                          </SelectContent>
                        </Select>
                      </div>

                      <div>
                        <Label htmlFor="explanation-style" className="font-medium">
                          When explaining complex concepts, do you prefer to:
                        </Label>
                        <Select>
                          <SelectTrigger id="explanation-style">
                            <SelectValue placeholder="Select your explanation style" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value="technical">Use technical terminology with detailed explanations</SelectItem>
                            <SelectItem value="simplified">Simplify concepts using analogies or everyday examples</SelectItem>
                            <SelectItem value="mixed">Provide a mix of technical and simplified language</SelectItem>
                            <SelectItem value="adaptive">Adjust based on the recipient's background</SelectItem>
                          </SelectContent>
                        </Select>
                      </div>

                      <div>
                        <Label htmlFor="oxford-comma" className="font-medium">
                          Do you use the Oxford comma (serial comma)?
                        </Label>
                        <RadioGroup onValueChange={setOxfordComma} value={oxfordComma}>
                          <div className="flex items-center space-x-2">
                            <RadioGroupItem value="yes" id="oxford-yes" />
                            <Label htmlFor="oxford-yes">Yes</Label>
                          </div>
                          <div className="flex items-center space-x-2">
                            <RadioGroupItem value="no" id="oxford-no" />
                            <Label htmlFor="oxford-no">No</Label>
                          </div>
                          <div className="flex items-center space-x-2">
                            <RadioGroupItem value="sometimes" id="oxford-sometimes" />
                            <Label htmlFor="oxford-sometimes">Sometimes</Label>
                          </div>
                        </RadioGroup>
                      </div>

                      <div>
                        <Label htmlFor="word-choice" className="font-medium">
                          Which word would you typically use?
                        </Label>
                        <RadioGroup onValueChange={setWordChoice} value={wordChoice}>
                          <div className="flex items-center space-x-2">
                            <RadioGroupItem value="client" id="word-client" />
                            <Label htmlFor="word-client">Client</Label>
                          </div>
                          <div className="flex items-center space-x-2">
                            <RadioGroupItem value="patient" id="word-patient" />
                            <Label htmlFor="word-patient">Patient</Label>
                          </div>
                          <div className="flex items-center space-x-2">
                            <RadioGroupItem value="individual" id="word-individual" />
                            <Label htmlFor="word-individual">Individual</Label>
                          </div>
                        </RadioGroup>
                      </div>

                      <div>
                        <Label htmlFor="writing-sample" className="font-medium">
                          Please write a brief paragraph (3-5 sentences) describing a typical day in your professional life. This will help us analyze your writing style.
                        </Label>
                        <Textarea
                          id="writing-sample"
                          placeholder="Start your paragraph here..."
                          className="mt-1"
                          rows={4}
                        />
                      </div>

                      <div>
                        <Label htmlFor="phrase-examples" className="font-medium">
                          Provide three phrases or expressions you frequently use in your professional writing:
                        </Label>
                        <div className="space-y-2 mt-1">
                          <Input id="phrase-1" placeholder="Phrase 1" />
                          <Input id="phrase-2" placeholder="Phrase 2" />
                          <Input id="phrase-3" placeholder="Phrase 3" />
                        </div>
                      </div>

                      <div>
                        <Label htmlFor="report-structure" className="font-medium">
                          How do you typically structure your assessment reports?
                        </Label>
                        <Textarea
                          id="report-structure"
                          placeholder="E.g., Background, Methods, Results, Discussion, Recommendations"
                          className="mt-1"
                          rows={3}
                        />
                      </div>

                      <div>
                        <Label htmlFor="specific-terms" className="font-medium">
                          Are there any specific phrases, terms, or frameworks you frequently use in your assessments?
                        </Label>
                        <Textarea
                          id="specific-terms"
                          placeholder="E.g., 'Areas of strength/challenge', 'Evidence-based interventions', specific theoretical models"
                          className="mt-1"
                          rows={3}
                        />
                      </div>
                    </div>
                  </section>

                  {/* Professional Approach */}
                  <section>
                    <h2 className="text-2xl font-semibold mb-4 text-primary">3. Professional Approach</h2>
                    <p className="mb-4 text-gray-600">
                      Describe a recent case where you felt your approach was particularly effective. Focus on your reasoning and decision-making process.
                    </p>
                    <Textarea
                      id="professional-approach"
                      placeholder="Describe the case, your approach, and why you believe it was effective..."
                      className="w-full"
                      rows={6}
                    />
                  </section>

                  {/* Advice Scenarios */}
                  <section>
                    <h2 className="text-2xl font-semibold mb-4 text-primary">4. Advice Scenarios</h2>
                    <p className="mb-4 text-gray-600">
                      Respond to these scenarios as if you were addressing the individual directly. Your responses will help the AI understand your communication style.
                    </p>
                    <div className="space-y-4">
                      <div>
                        <Label htmlFor="adhd-scenario" className="font-medium">A parent is hesitant about their child's ADHD diagnosis. How would you address their concerns?</Label>
                        <Textarea
                          id="adhd-scenario"
                          placeholder="Your response to the parent..."
                          className="mt-1"
                          rows={4}
                        />
                      </div>
                      <div>
                        <Label htmlFor="teacher-scenario" className="font-medium">A teacher is struggling with a student's behavior in class. What strategies would you suggest?</Label>
                        <Textarea
                          id="teacher-scenario"
                          placeholder="Your advice to the teacher..."
                          className="mt-1"
                          rows={4}
                        />
                      </div>
                    </div>
                  </section>

                  {/* Decision-Making Process */}
                  <section>
                    <h2 className="text-2xl font-semibold mb-4 text-primary">5. Decision-Making Process</h2>
                    <p className="mb-4 text-gray-600">
                      Describe how you typically approach making a diagnosis or recommendation. What factors do you consider most important?
                    </p>
                    <Textarea
                      id="decision-making"
                      placeholder="Outline your typical decision-making process..."
                      className="w-full"
                      rows={6}
                    />
                  </section>

                  {/* Preferred Resources */}
                  <section>
                    <h2 className="text-2xl font-semibold mb-4 text-primary">6. Preferred Resources</h2>
                    <p className="mb-4 text-gray-600">
                      What are your go-to resources or frameworks when formulating assessments or interventions?
                    </p>
                    <Textarea
                      id="preferred-resources"
                      placeholder="List your preferred assessment tools, theoretical frameworks, or other resources..."
                      className="w-full"
                      rows={4}
                    />
                  </section>

                  {/* AI Collaboration Preferences */}
                  <section>
                    <h2 className="text-2xl font-semibold mb-4 text-primary">7. AI Collaboration Preferences</h2>
                    <p className="mb-4 text-gray-600">
                      How would you like the AI to support your work? Select all that apply:
                    </p>
                    <div className="space-y-2">
                      {[
                        "Suggest relevant research or case studies",
                        "Highlight potential patterns or correlations in assessment data",
                        "Draft preliminary assessment summaries for review",
                        "Propose follow-up questions during client interviews",
                        "Generate tailored intervention suggestions based on assessment outcomes"
                      ].map((preference, index) => (
                        <div key={index} className="flex items-center">
                          <Checkbox id={`preference-${index}`} />
                          <Label htmlFor={`preference-${index}`} className="ml-2">{preference}</Label>
                        </div>
                      ))}
                    </div>
                  </section>

                  <Button className="w-full">Save Preferences</Button>
                </CardContent>
              </Card>
            </div>
          )}
          {selectedSetting === 'myCommunications' && (
            <div className="space-y-6">
              <h3 className="font-semibold mb-2">Communication Settings</h3>
              <div className="space-y-4">
                <div>
                  <Label htmlFor="systemEmailFrom">Emails from the system should be sent from:</Label>
                  <Select defaultValue="myEmail">
                    <SelectTrigger id="systemEmailFrom">
                      <SelectValue placeholder="Select email" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="myEmail">My Email</SelectItem>
                      <SelectItem value="officeEmail">Office Email</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
                <div>
                  <Label htmlFor="emailInbox">My Email Inbox is:</Label>
                  <Select defaultValue="shared">
                    <SelectTrigger id="emailInbox">
                      <SelectValue placeholder="Select inbox type" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="shared">Shared</SelectItem>
                      <SelectItem value="private">Private</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
                <div>
                  <Label htmlFor="emailSignature">Signature to end your emails:</Label>
                  <textarea
                    id="emailSignature"
                    className="w-full h-24 p-2 border rounded mt-1"
                    defaultValue="Best regards,
Dr. John Smith
Clinical Psychologist
ABC Clinic"
                  />
                </div>
                <div>
                  <Label htmlFor="contactPhone">Phone number you give to contact you:</Label>
                  <Select defaultValue="mine">
                    <SelectTrigger id="contactPhone">
                      <SelectValue placeholder="Select phone number" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="mine">Mine</SelectItem>
                      <SelectItem value="office">My Office</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
                <div>
                  <Label htmlFor="bookingLink">Booking Link:</Label>
                  <div className="flex items-center space-x-2">
                    <span className="text-gray-500">https://mypsychaccess.com/clinic/</span>
                    <Input
                      id="bookingLink"
                      value={bookingSlug}
                      onChange={(e) => setBookingSlug(e.target.value)}
                      className="w-32"
                    />
                  </div>
                </div>
                <div className="flex items-center space-x-2">
                  <Checkbox
                    id="useCustomDomain"
                    checked={useCustomDomain}
                    onCheckedChange={setUseCustomDomain}
                  />
                  <Label htmlFor="useCustomDomain">Use Custom Domain</Label>
                </div>
                {useCustomDomain && (
                  <div>
                    {customDomain ? (
                      <Input
                        value={customDomain}
                        onChange={(e) => setCustomDomain(e.target.value)}
                        placeholder="Enter your custom domain"
                      />
                    ) : (
                      <Link to="/settings/communications/domains">
                        <Button variant="link">Setup Custom Domain</Button>
                      </Link>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
          {selectedSetting === 'myCalendar' && (
            <div>
              {/* Add content for My Calendar */}
              <p>Set up your personal calendar preferences here.</p>
            </div>
          )}
          {selectedSetting === 'dataManagement' && (
            <div className="space-y-6">
              <h3 className="text-xl font-semibold">Data Fields</h3>
              <Card>
                <CardHeader>
                  <CardTitle>Patient Name</CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="space-y-4">
                    <div>
                      <Label htmlFor="fieldName">Field Name</Label>
                      <Input id="fieldName" defaultValue="Patient Name" />
                    </div>
                    <div>
                      <Label htmlFor="externalFieldName">External Field Name</Label>
                      <Input id="externalFieldName" defaultValue="patient_name" />
                    </div>
                    <div>
                      <Label htmlFor="fieldType">Field Type</Label>
                      <Input id="fieldType" defaultValue="Free Text" disabled />
                    </div>
                    <div className="flex items-center space-x-2">
                      <Checkbox id="clinicalData" />
                      <Label htmlFor="clinicalData">Clinical Data</Label>
                    </div>
                    <div>
                      <Label>Shown on</Label>
                      <div className="space-y-2 mt-2">
                        {['Patient Profile', 'Add Patient', 'Assessment Form'].map((screen) => (
                          <div key={screen} className="flex items-center space-x-2">
                            <Checkbox id={`screen-${screen}`} />
                            <Label htmlFor={`screen-${screen}`}>{screen}</Label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>

              <div className="mt-6">
                <Button
                  onClick={() => setShowAddQuestion(!showAddQuestion)}
                  className="mb-4"
                >
                  <PlusCircle className="mr-2 h-4 w-4" />
                  Add Question
                </Button>

                {showAddQuestion && (
                  <Card className="mt-4">
                    <CardHeader>
                      <CardTitle>Add New Question</CardTitle>
                    </CardHeader>
                    <CardContent>
                      <div className="space-y-4">
                        <div>
                          <Label htmlFor="uid">UID</Label>
                          <Input id="uid" value={questions.length + 1} disabled />
                        </div>
                        <div>
                          <Label htmlFor="questionName">Question Name</Label>
                          <Input
                            id="questionName"
                            value={newQuestion.questionName}
                            onChange={(e) => setNewQuestion({ ...newQuestion, questionName: e.target.value })}
                          />
                        </div>
                        <div>
                          <Label htmlFor="questionText">What Is The Question</Label>
                          <Textarea
                            id="questionText"
                            value={newQuestion.questionText}
                            onChange={(e) => setNewQuestion({ ...newQuestion, questionText: e.target.value })}
                          />
                        </div>
                        <div>
                          <Label htmlFor="answerType">How is it answered</Label>
                          <Select
                            value={newQuestion.answerType}
                            onValueChange={(value) => setNewQuestion({ ...newQuestion, answerType: value })}
                          >
                            <SelectTrigger id="answerType">
                              <SelectValue placeholder="Select answer type" />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectItem value="radioButtons">Radio Buttons</SelectItem>
                              <SelectItem value="freeText">Free Text</SelectItem>
                              <SelectItem value="alpha">Alpha</SelectItem>
                              <SelectItem value="checkbox">Checkbox</SelectItem>
                            </SelectContent>
                          </Select>
                        </div>
                        {newQuestion.answerType === 'freeText' && (
                          <div>
                            <Label htmlFor="freeTextAnswer">Free Text Answer</Label>
                            <Input id="freeTextAnswer" placeholder="User will enter text here" disabled />
                          </div>
                        )}
                        <div className="flex items-center space-x-2">
                          <Checkbox
                            id="isClinical"
                            checked={newQuestion.isClinical}
                            onCheckedChange={(checked) => setNewQuestion({ ...newQuestion, isClinical: checked })}
                          />
                          <Label htmlFor="isClinical">Is this clinical</Label>
                        </div>
                        <Button onClick={handleAddQuestion}>Submit</Button>
                      </div>
                    </CardContent>
                  </Card>
                )}

                {questions.length > 0 && (
                  <div className="mt-6">
                    <h4 className="text-lg font-semibold mb-2">Added Questions</h4>
                    {questions.map((q) => (
                      <Card key={q.uid} className="mb-4">
                        <CardHeader>
                          <CardTitle>{q.questionName}</CardTitle>
                        </CardHeader>
                        <CardContent>
                          <p><strong>UID:</strong> {q.uid}</p>
                          <p><strong>Question:</strong> {q.questionText}</p>
                          <p><strong>Answer Type:</strong> {q.answerType}</p>
                          <p><strong>Is Clinical:</strong> {q.isClinical ? 'Yes' : 'No'}</p>
                        </CardContent>
                      </Card>
                    ))}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )
    }
    
    // Render existing content for other categories
    return (
      <div>
        <h1 className="text-2xl font-bold mb-4">
          {settingsData[selectedCategory][selectedSetting].title}
        </h1>
        <p className="mb-6">{settingsData[selectedCategory][selectedSetting].content}</p>
        
        {/* Example settings UI */}
        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <Label htmlFor="setting1">Enable feature</Label>
            <Switch id="setting1" onCheckedChange={handleChange} />
          </div>
          <div className="flex items-center justify-between">
            <Label htmlFor="setting2">Notifications</Label>
            <Switch id="setting2" onCheckedChange={handleChange} />
          </div>
          <div>
            <Label htmlFor="setting3">CNAME Entries</Label>
            <Input id="setting3" type="password" className="mt-1" onChange={handleChange} />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="flex h-screen bg-gray-100 dark:bg-gray-900">
      <div className="flex flex-col w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
        <div className="mb-6">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <Input
              type="text"
              placeholder="What are you looking for?"
              className="pl-10 pr-4 py-2 w-full"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </div>
        
        <div className="flex flex-1 gap-6">
          <ScrollArea className="w-64 bg-white dark:bg-gray-800 rounded-lg shadow">
            <nav className="p-4">
              {Object.entries(filteredSettings).map(([category, subItems]) => (
                <div key={category} className="mb-6">
                  <h2 className="text-lg font-semibold mb-2 capitalize">{category}</h2>
                  <ul className="space-y-1">
                    {Object.entries(subItems).map(([key, { title }]) => (
                      <li key={key}>
                        <Button
                          variant="ghost"
                          className={`w-full justify-start ${
                            selectedCategory === category && selectedSetting === key
                              ? 'bg-gray-100 dark:bg-gray-700'
                              : ''
                          }`}
                          onClick={() => handleCategoryChange(category, key)}
                        >
                          {title}
                          <ChevronRight className="ml-auto h-4 w-4" />
                        </Button>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </nav>
          </ScrollArea>
          
          <main className="flex-1 bg-white dark:bg-gray-800 rounded-lg shadow p-6 overflow-auto">
            {renderContent()}
          </main>
        </div>
      </div>
      
      {/* Save/Discard Bar */}
      {hasChanges && (
        <div className="fixed bottom-0 left-0 right-0 bg-gray-800 text-white p-4 flex justify-between items-center transition-all duration-300 ease-in-out transform translate-y-0">
          <span>You have unsaved changes</span>
          <div className="space-x-2">
            <Button variant="outline" onClick={handleDiscard} className="text-white border-white hover:bg-gray-700">
              <X className="mr-2 h-4 w-4" /> Discard
            </Button>
            <Button onClick={handleSave} className="bg-green-500 hover:bg-green-600">
              <Save className="mr-2 h-4 w-4" /> Save Changes
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}
