import React, { useState } from 'react';
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../components/ui/tabs";
import { ScrollArea } from "../components/ui/scroll-area";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "../components/ui/collapsible";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "../components/ui/dialog";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../components/ui/select";
import { Textarea } from "../components/ui/textarea";
import { RadioGroup, RadioGroupItem } from "../components/ui/radio-group";
import { Label } from "../components/ui/label";
import { Checkbox } from "../components/ui/checkbox";
import { Search, Plus, Grid, List, ChevronDown, ChevronUp, Info, Edit, PlusCircle, X, Send, UserPlus, Calendar, Clock, Link } from 'lucide-react';

// Mock data for forms
const forms = [
  { 
    id: 1, 
    name: 'General Intake Questionnaire', 
    category: 'Intake', 
    lastUsed: '2 days ago',
    description: 'A comprehensive intake form for new patients',
    intendedUse: 'To gather initial patient information',
    avgCompletionTime: '15 minutes',
    lastUpdated: '2023-05-15',
    relatedForms: ['Medical History', 'Insurance Information'],
    editable: true,
    content: [
      { type: 'text', label: 'Full Name', required: true },
      { type: 'date', label: 'Date of Birth', required: true },
      { type: 'radio', label: 'Gender', options: ['Male', 'Female', 'Other'], required: true },
      { type: 'textarea', label: 'Reason for Visit', required: true },
    ]
  },
  {
    id: 2,
    name: 'Informed Consent for Therapy',
    category: 'Consent',
    lastUsed: '1 week ago',
    description: 'Standard consent form for therapy services',
    intendedUse: 'To obtain patient consent for treatment',
    avgCompletionTime: '10 minutes',
    lastUpdated: '2023-04-20',
    relatedForms: ['HIPAA Privacy Notice', 'Financial Agreement'],
    editable: false,
    content: [
      { type: 'text', label: 'Patient Name', required: true },
      { type: 'checkbox', label: 'I agree to the terms of service', required: true },
      { type: 'signature', label: 'Patient Signature', required: true },
    ]
  },
  {
    id: 3,
    name: 'Depression Screening (PHQ-9)',
    category: 'Assessment',
    lastUsed: '3 days ago',
    description: 'Patient Health Questionnaire for depression screening',
    intendedUse: 'To assess severity of depression symptoms',
    avgCompletionTime: '5 minutes',
    lastUpdated: '2023-03-10',
    relatedForms: ['Anxiety Screening (GAD-7)', 'Suicide Risk Assessment'],
    editable: true,
    content: [
      { type: 'radio', label: 'Little interest or pleasure in doing things', options: ['Not at all', 'Several days', 'More than half the days', 'Nearly every day'], required: true },
      { type: 'radio', label: 'Feeling down, depressed, or hopeless', options: ['Not at all', 'Several days', 'More than half the days', 'Nearly every day'], required: true },
      // ... other PHQ-9 questions
    ]
  },
  {
    id: 4,
    name: 'Substance Use Screening',
    category: 'Screening',
    lastUsed: '5 days ago',
    description: 'Questionnaire to assess substance use patterns',
    intendedUse: 'To identify potential substance abuse issues',
    avgCompletionTime: '8 minutes',
    lastUpdated: '2023-02-28',
    relatedForms: ['CAGE Assessment', 'Alcohol Use Disorders Identification Test (AUDIT)'],
    editable: true,
    content: [
      { type: 'radio', label: 'How often do you have a drink containing alcohol?', options: ['Never', 'Monthly or less', '2-4 times a month', '2-3 times a week', '4 or more times a week'], required: true },
      { type: 'checkbox', label: 'Which substances have you used in the past 6 months?', options: ['Tobacco', 'Alcohol', 'Marijuana', 'Cocaine', 'Heroin', 'Prescription medications (not as prescribed)'], required: true },
    ]
  },
  {
    id: 5,
    name: 'Cognitive Behavioral Therapy (CBT) Thought Record',
    category: 'Treatment',
    lastUsed: 'Today',
    description: 'Worksheet for identifying and challenging negative thoughts',
    intendedUse: 'To help patients recognize and modify cognitive distortions',
    avgCompletionTime: '20 minutes',
    lastUpdated: '2023-05-01',
    relatedForms: ['Behavioral Activation Worksheet', 'Cognitive Restructuring Guide'],
    editable: true,
    content: [
      { type: 'text', label: 'Situation', required: true },
      { type: 'textarea', label: 'Automatic Thoughts', required: true },
      { type: 'textarea', label: 'Evidence For', required: true },
      { type: 'textarea', label: 'Evidence Against', required: true },
      { type: 'textarea', label: 'Alternative Thought', required: true },
    ]
  },
  {
    id: 6,
    name: 'Therapy Session Feedback',
    category: 'Feedback',
    lastUsed: '1 day ago',
    description: 'Form for patients to provide feedback after therapy sessions',
    intendedUse: 'To gather patient perspectives and improve therapy quality',
    avgCompletionTime: '5 minutes',
    lastUpdated: '2023-04-15',
    relatedForms: ['Treatment Satisfaction Survey', 'Therapist Evaluation Form'],
    editable: true,
    content: [
      { type: 'radio', label: "How helpful was today's session?", options: ['Not at all', 'Slightly', 'Moderately', 'Very', 'Extremely'], required: true },
      { type: 'textarea', label: 'What was the most valuable part of the session?', required: false },
      { type: 'textarea', label: 'Is there anything you wish was different about the session?', required: false },
    ]
  },
  {
    id: 7,
    name: 'Psychiatric Referral Form',
    category: 'Referral',
    lastUsed: '4 days ago',
    description: 'Form for referring patients to psychiatric services',
    intendedUse: 'To facilitate smooth referrals to psychiatrists',
    avgCompletionTime: '10 minutes',
    lastUpdated: '2023-03-20',
    relatedForms: ['Medical History Summary', 'Current Medication List'],
    editable: true,
    content: [
      { type: 'text', label: 'Patient Name', required: true },
      { type: 'date', label: 'Date of Referral', required: true },
      { type: 'textarea', label: 'Reason for Referral', required: true },
      { type: 'textarea', label: 'Current Symptoms', required: true },
      { type: 'textarea', label: 'Current Medications', required: true },
      { type: 'text', label: 'Referring Provider Name', required: true },
    ]
  },
];

const categories = ['Intake', 'Consent', 'Assessment', 'Screening', 'Treatment', 'Feedback', 'Referral'];

// Mock data for patients and stakeholders
const patients = [
  { id: 1, name: 'Alice Johnson' },
  { id: 2, name: 'Bob Smith' },
  { id: 3, name: 'Charlie Brown' },
];

const stakeholders = [
  { id: 1, name: 'John Smith', role: 'School Principal' },
  { id: 2, name: 'Sarah Lee', role: 'Social Worker' },
  { id: 3, name: 'Mike Johnson', role: 'Pediatrician' },
];

const FormCard = ({ form, onAddToEnvelope, inEnvelope, onPreview, onInfo, onEdit }) => (
  <Card className="w-full">
    <CardHeader className="pb-2">
      <CardTitle className="text-lg">
        <button onClick={() => onPreview(form)} className="hover:underline focus:outline-none">
          {form.name}
        </button>
      </CardTitle>
      <span className="text-sm bg-gray-200 px-2 py-1 rounded">{form.category}</span>
    </CardHeader>
    <CardContent>
      <div className="w-full h-24 bg-gray-200 rounded-md mb-2 cursor-pointer" onClick={() => onPreview(form)}></div>
      <p className="text-sm text-gray-500">Last used: {form.lastUsed}</p>
      <div className="flex justify-between mt-2">
        <Button variant="ghost" size="sm" onClick={() => onInfo(form)}><Info className="w-4 h-4 mr-1" /> Info</Button>
        {form.editable && <Button variant="ghost" size="sm" onClick={() => onEdit(form)}><Edit className="w-4 h-4 mr-1" /> Edit</Button>}
        <Button 
          variant={inEnvelope ? "destructive" : "default"} 
          size="sm"
          onClick={() => onAddToEnvelope(form)}
        >
          {inEnvelope ? <X className="w-4 h-4 mr-1" /> : <PlusCircle className="w-4 h-4 mr-1" />}
          {inEnvelope ? "Remove" : "Add"}
        </Button>
      </div>
    </CardContent>
  </Card>
);

const SendFormsDialog = ({ envelope, onClose }) => {
  const [selectedPatient, setSelectedPatient] = useState('');
  const [selectedStakeholders, setSelectedStakeholders] = useState([]);
  const [newRecipient, setNewRecipient] = useState({ name: '', role: '', contact: '' });
  const [message, setMessage] = useState('');
  const [sendOption, setSendOption] = useState('email');

  const handleAddNewRecipient = () => {
    if (newRecipient.name && newRecipient.role && newRecipient.contact) {
      setSelectedStakeholders([...selectedStakeholders, newRecipient]);
      setNewRecipient({ name: '', role: '', contact: '' });
    }
  };

  return (
    <DialogContent className="max-w-2xl">
      <DialogHeader>
        <DialogTitle>Send Forms</DialogTitle>
        <DialogDescription>Choose recipients and review forms before sending.</DialogDescription>
      </DialogHeader>
      <div className="grid gap-4 py-4">
        <div className="grid grid-cols-4 items-center gap-4">
          <Label htmlFor="patient" className="text-right">
            Patient
          </Label>
          <Select value={selectedPatient} onValueChange={setSelectedPatient}>
            <SelectTrigger className="col-span-3">
              <SelectValue placeholder="Select patient" />
            </SelectTrigger>
            <SelectContent>
              {patients.map((patient) => (
                <SelectItem key={patient.id} value={patient.id.toString()}>{patient.name}</SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className="grid grid-cols-4 items-center gap-4">
          <Label htmlFor="stakeholders" className="text-right">
            Stakeholders
          </Label>
          <div className="col-span-3">
            {stakeholders.map((stakeholder) => (
              <div key={stakeholder.id} className="flex items-center space-x-2">
                <Checkbox 
                  id={`stakeholder-${stakeholder.id}`}
                  checked={selectedStakeholders.some(s => s.id === stakeholder.id)}
                  onCheckedChange={(checked) => {
                    if (checked) {
                      setSelectedStakeholders([...selectedStakeholders, stakeholder]);
                    } else {
                      setSelectedStakeholders(selectedStakeholders.filter(s => s.id !== stakeholder.id));
                    }
                  }}
                />
                <Label htmlFor={`stakeholder-${stakeholder.id}`}>{stakeholder.name} ({stakeholder.role})</Label>
              </div>
            ))}
          </div>
        </div>
        <div className="grid grid-cols-4 items-center gap-4">
          <Label className="text-right">New Recipient</Label>
          <div className="col-span-3 space-y-2">
            <Input 
              placeholder="Name" 
              value={newRecipient.name}
              onChange={(e) => setNewRecipient({...newRecipient, name: e.target.value})}
            />
            <Input 
              placeholder="Role" 
              value={newRecipient.role}
              onChange={(e) => setNewRecipient({...newRecipient, role: e.target.value})}
            />
            <Input 
              placeholder="Contact Information" 
              value={newRecipient.contact}
              onChange={(e) => setNewRecipient({...newRecipient, contact: e.target.value})}
            />
            <Button onClick={handleAddNewRecipient}>
              <UserPlus className="w-4 h-4 mr-2" />
              Add Recipient
            </Button>
          </div>
        </div>
        <div className="grid grid-cols-4 items-start gap-4">
          <Label className="text-right">Forms to Send</Label>
          <div className="col-span-3">
            {envelope.map((form) => (
              <div key={form.id} className="flex items-center justify-between py-1">
                <span>{form.name}</span>
                <Button variant="ghost" size="sm">
                  <X className="w-4 h-4" />
                </Button>
              </div>
            ))}
          </div>
        </div>
        <div className="grid grid-cols-4 items-center gap-4">
          <Label htmlFor="message" className="text-right">
            Message
          </Label>
          <Textarea 
            id="message" 
            placeholder="Add an optional cover message" 
            className="col-span-3"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>
        <div className="grid grid-cols-4 items-center gap-4">
          <Label className="text-right">Send Options</Label>
          <RadioGroup 
            className="col-span-3"
            value={sendOption}
            onValueChange={setSendOption}
          >
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="email" id="email" />
              <Label htmlFor="email">Email</Label>
            </div>
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="portal" id="portal" />
              <Label htmlFor="portal">Patient Portal</Label>
            </div>
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="pdf" id="pdf" />
              <Label htmlFor="pdf">Generate Print-ready PDF</Label>
            </div>
          </RadioGroup>
        </div>
      </div>
      <DialogFooter>
        <Button type="submit">Send Forms</Button>
      </DialogFooter>
    </DialogContent>
  );
};

const FormPreviewModal = ({ form, onClose, onAddToEnvelope, onEdit }) => (
  <DialogContent className="max-w-4xl max-h-[80vh] overflow-y-auto">
    <DialogHeader>
      <DialogTitle>{form.name}</DialogTitle>
      <DialogDescription>Preview of the form</DialogDescription>
    </DialogHeader>
    <div className="py-4">
      {form.content.map((field, index) => (
        <div key={index} className="mb-4">
          <Label htmlFor={`field-${index}`}>{field.label}</Label>
          {field.type === 'text' && <Input id={`field-${index}`} placeholder={field.label} disabled />}
          {field.type === 'date' && <Input id={`field-${index}`} type="date" disabled />}
          {field.type === 'radio' && (
            <RadioGroup>
              {field.options.map((option, optionIndex) => (
                <div key={optionIndex} className="flex items-center space-x-2">
                  <RadioGroupItem value={option} id={`${field.label}-${option}`} disabled />
                  <Label htmlFor={`${field.label}-${option}`}>{option}</Label>
                </div>
              ))}
            </RadioGroup>
          )}
          {field.type === 'textarea' && <Textarea id={`field-${index}`} placeholder={field.label} disabled />}
        </div>
      ))}
    </div>
    <DialogFooter>
      <Button onClick={() => onAddToEnvelope(form)}>Use This Form</Button>
      {form.editable && <Button onClick={() => onEdit(form)}>Edit</Button>}
      <Button variant="outline" onClick={onClose}>Close Preview</Button>
    </DialogFooter>
  </DialogContent>
);

const FormInformationSidebar = ({ form, onClose }) => (
  <div className="fixed inset-y-0 right-0 w-64 bg-white shadow-lg p-4 overflow-y-auto z-50">
    <div className="flex justify-between items-center mb-4">
      <h2 className="text-xl font-bold">{form.name}</h2>
      <Button variant="ghost" size="sm" onClick={onClose}>
        <X className="h-4 w-4" />
      </Button>
    </div>
    <div className="space-y-4">
      <div>
        <h3 className="font-semibold">Description</h3>
        <p>{form.description}</p>
      </div>
      <div>
        <h3 className="font-semibold">Intended Use</h3>
        <p>{form.intendedUse}</p>
      </div>
      <div className="flex items-center">
        <Clock className="w-4 h-4 mr-2" />
        <span>Average completion time: {form.avgCompletionTime}</span>
      </div>
      <div className="flex items-center">
        <Calendar className="w-4 h-4 mr-2" />
        <span>Last updated: {form.lastUpdated}</span>
      </div>
      <div>
        <h3 className="font-semibold">Related Forms</h3>
        <ul className="list-disc list-inside">
          {form.relatedForms.map((relatedForm, index) => (
            <li key={index}>{relatedForm}</li>
          ))}
        </ul>
      </div>
    </div>
  </div>
);

const FormsLibrary = () => {
  const [view, setView] = useState('grid');
  const [searchTerm, setSearchTerm] = useState('');
  const [envelope, setEnvelope] = useState([]);
  const [expandedCategories, setExpandedCategories] = useState(categories.reduce((acc, category) => ({ ...acc, [category]: true }), {}));
  const [sendFormsOpen, setSendFormsOpen] = useState(false);
  const [previewForm, setPreviewForm] = useState(null);
  const [infoForm, setInfoForm] = useState(null);
  const [editForm, setEditForm] = useState(null);

  const filteredForms = forms.filter(form => 
    form.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    form.category.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const toggleCategory = (category) => {
    setExpandedCategories(prev => ({ ...prev, [category]: !prev[category] }));
  };

  const addToEnvelope = (form) => {
    if (envelope.find(f => f.id === form.id)) {
      setEnvelope(envelope.filter(f => f.id !== form.id));
    } else {
      setEnvelope([...envelope, form]);
    }
  };

  const handleEditForm = (form) => {
    setEditForm(form);
    // Here you would typically navigate to the Form Editor component
    console.log('Navigating to Form Editor with form:', form);
  };

  return (
    <div className="container mx-auto p-4 flex">
      <div className="flex-grow mr-4">
        <div className="flex justify-between items-center mb-4">
          <div className="relative w-1/2">
            <Search className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <Input
              type="text"
              placeholder="Search forms..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="pl-8"
            />
          </div>
          <div className="flex items-center space-x-2">
            <Button onClick={() => handleEditForm({})}><Plus className="w-4 h-4 mr-1" /> Create New Form</Button>
            <Button variant="outline" onClick={() => setView('grid')}><Grid className="w-4 h-4" /></Button>
            <Button variant="outline" onClick={() => setView('list')}><List className="w-4 h-4" /></Button>
          </div>
        </div>

        <Card className="mb-4">
          <CardHeader>
            <CardTitle>Your Forms</CardTitle>
          </CardHeader>
          <CardContent>
            <div className={`grid ${view === 'grid' ? 'grid-cols-1 sm:grid-cols-2 lg:grid-cols-3' : 'grid-cols-1'} gap-4`}>
              {filteredForms.slice(0, 3).map(form => (
                <FormCard 
                  key={form.id} 
                  form={form} 
                  onAddToEnvelope={addToEnvelope}
                  inEnvelope={envelope.some(f => f.id === form.id)}
                  onPreview={() => setPreviewForm(form)}
                  onInfo={() => setInfoForm(form)}
                  onEdit={() => handleEditForm(form)}
                />
              ))}
            </div>
            <Button variant="link" className="mt-4">Manage Your Forms</Button>
          </CardContent>
        </Card>

        <Tabs defaultValue="all">
          <TabsList>
            <TabsTrigger value="all">All Forms</TabsTrigger>
            <TabsTrigger value="recent">Recently Used</TabsTrigger>
          </TabsList>
          <TabsContent value="all">
            {categories.map(category => (
              <Collapsible key={category} open={expandedCategories[category]} onOpenChange={() => toggleCategory(category)}>
                <CollapsibleTrigger asChild>
                  <Button variant="ghost" className="w-full justify-between mb-2">
                    {category}
                    {expandedCategories[category] ? <ChevronUp className="w-4 h-4" /> : <ChevronDown className="w-4 h-4" />}
                  </Button>
                </CollapsibleTrigger>
                <CollapsibleContent>
                  <div className={`grid ${view === 'grid' ? 'grid-cols-1 sm:grid-cols-2 lg:grid-cols-3' : 'grid-cols-1'} gap-4 mb-4`}>
                    {filteredForms.filter(form => form.category === category).map(form => (
                      <FormCard 
                        key={form.id} 
                        form={form} 
                        onAddToEnvelope={addToEnvelope}
                        inEnvelope={envelope.some(f => f.id === form.id)}
                        onPreview={() => setPreviewForm(form)}
                        onInfo={() => setInfoForm(form)}
                        onEdit={() => handleEditForm(form)}
                      />
                    ))}
                  </div>
                </CollapsibleContent>
              </Collapsible>
            ))}
          </TabsContent>
          <TabsContent value="recent">
            <div className={`grid ${view === 'grid' ? 'grid-cols-1 sm:grid-cols-2 lg:grid-cols-3' : 'grid-cols-1'} gap-4`}>
              {filteredForms.slice(0, 5).map(form => (
                <FormCard 
                  key={form.id} 
                  form={form} 
                  onAddToEnvelope={addToEnvelope}
                  inEnvelope={envelope.some(f => f.id === form.id)}
                  onPreview={() => setPreviewForm(form)}
                  onInfo={() => setInfoForm(form)}
                  onEdit={() => handleEditForm(form)}
                />
              ))}
            </div>
          </TabsContent>
        </Tabs>
      </div>

      <Card className="w-64 h-fit sticky top-4">
        <CardHeader>
          <CardTitle>Envelope</CardTitle>
        </CardHeader>
        <CardContent>
          <ScrollArea className="h-[300px]">
            {envelope.map(form => (
              <div key={form.id} className="flex justify-between items-center mb-2">
                <span className="text-sm">{form.name}</span>
                <Button variant="ghost" size="sm" onClick={() => addToEnvelope(form)}>
                  <X className="w-4 h-4" />
                </Button>
              </div>
            ))}
          </ScrollArea>
          <div className="flex flex-col space-y-2 mt-4">
            <Dialog open={sendFormsOpen} onOpenChange={setSendFormsOpen}>
              <DialogTrigger asChild>
                <Button className="w-full" disabled={envelope.length === 0}>
                  <Send className="w-4 h-4 mr-1" /> Send Forms ({envelope.length})
                </Button>
              </DialogTrigger>
              <SendFormsDialog envelope={envelope} onClose={() => setSendFormsOpen(false)} />
            </Dialog>
            <Button variant="outline" className="w-full" onClick={() => setEnvelope([])}>
              Clear Envelope
            </Button>
          </div>
        </CardContent>
      </Card>

      <Dialog open={previewForm !== null} onOpenChange={() => setPreviewForm(null)}>
        {previewForm && (
          <FormPreviewModal 
            form={previewForm} 
            onClose={() => setPreviewForm(null)}
            onAddToEnvelope={addToEnvelope}
            onEdit={() => {
              handleEditForm(previewForm);
              setPreviewForm(null);
            }}
          />
        )}
      </Dialog>

      {infoForm && (
        <FormInformationSidebar 
          form={infoForm} 
          onClose={() => setInfoForm(null)}
        />
      )}
    </div>
  );
};

export default FormsLibrary;